import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {NzButtonModule} from 'ng-zorro-antd/button';
import {NzIconModule} from 'ng-zorro-antd/icon';

import {DiViewHeaderComponent} from 'app/common/di-view-header/di-view-header.component';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {FlexLayoutModule} from '@angular/flex-layout';
import {DiHelpModule} from 'app/common/di-help/di-help.module';
import {FuseSharedModule} from '@fuse/shared.module';

@NgModule({
    declarations: [DiViewHeaderComponent],
    imports: [CommonModule, TranslateModule, NzIconModule, NzButtonModule, FontAwesomeModule, FlexLayoutModule, DiHelpModule, FuseSharedModule],
    exports: [DiViewHeaderComponent],
    providers: []
})
export class DiViewHeaderModule {}
