import {BaseEntity, IBaseEntity} from 'app/blocks/model/base-entity.model';

export enum WarehouseReturnType {
    REDELIVERY = 'REDELIVERY',
    REFUSAL = 'REFUSAL',
    COLLECTION = 'COLLECTION',
    NONE = 'NONE'
}

export enum WarehouseLoadingIssue {
    NONE = 'NONE',
    PACKAGING_DAMAGED = 'PACKAGING_IS_DAMAGED',
    EQUIPMENT_DAMAGED = 'EQUIPMENT_IS_DAMAGED',
    SHORT = 'SHORT',
    CAPTURE_OVER = 'CAPTURE_OVER'
}

export interface IGoodsInfo extends IBaseEntity {
    id: number;
    goodsNo: string;
    goodsDescription: string;
    trackingNo: string;
    groupNo: string;
    startDate: string | null;
    deliveryDate: string | null;
    confirmedLoad: boolean;
    warehouseReturnType: WarehouseReturnType | null;
    warehouseReturnFirstCaptured: string | null;
    haulierOrderId: number | null;
    haulierOrderNo: number | null;
    runIds: string | null;
    loadNumber: string | null;
    docketNumber: string | null;
    customerName: string | null;
    collectionPoint: string | null;
    deliveryPoint: string | null;
    warehouseLoadingIssue: WarehouseLoadingIssue;
    createdOn: Date;
}

export class GoodsInfo extends BaseEntity implements IGoodsInfo {
    id: number;
    goodsNo: string;
    goodsDescription: string;
    trackingNo: string;
    groupNo: string;
    startDate: string | null;
    deliveryDate: string | null;
    confirmedLoad: boolean;
    warehouseReturnType: WarehouseReturnType | null;
    warehouseReturnFirstCaptured: string | null;
    haulierOrderId: number | null;
    haulierOrderNo: number | null;
    runIds: string | null;
    loadNumber: string | null;
    docketNumber: string | null;
    customerName: string | null;
    collectionPoint: string | null;
    deliveryPoint: string | null;
    warehouseLoadingIssue: WarehouseLoadingIssue;
    createdOn: Date;

    constructor(goodsInfo?) {
        super();
        if (goodsInfo) {
            this.id = goodsInfo.id;
            this.goodsNo = goodsInfo.goodsNo;
            this.goodsDescription = goodsInfo.goodsDescription;
            this.trackingNo = goodsInfo.trackingNo;
            this.groupNo = goodsInfo.groupNo;
            this.startDate = goodsInfo.startDate;
            this.deliveryDate = goodsInfo.deliveryDate;
            this.confirmedLoad = goodsInfo.confirmedLoad;
            this.warehouseReturnType = goodsInfo.warehouseReturnType || WarehouseReturnType.NONE;
            this.warehouseReturnFirstCaptured = goodsInfo.warehouseReturnFirstCaptured;
            this.haulierOrderId = goodsInfo.haulierOrderId;
            this.haulierOrderNo = goodsInfo.haulierOrderNo;
            this.runIds = goodsInfo.runIds;
            this.loadNumber = goodsInfo.loadNumber;
            this.docketNumber = goodsInfo.docketNumber;
            this.customerName = goodsInfo.customerName;
            this.collectionPoint = goodsInfo.collectionPoint;
            this.deliveryPoint = goodsInfo.deliveryPoint;
            this.warehouseLoadingIssue = goodsInfo.warehouseLoadingIssue || WarehouseLoadingIssue.NONE;
            this.createdOn = goodsInfo.createdOn;
        }
    }

    get discriminator(): any {
        return this.id;
    }

    clone(): GoodsInfo {
        return new GoodsInfo(this);
    }
}
