import {Injectable} from '@angular/core';
import {EntityAction} from 'app/blocks/model/action.model';
import {Subject} from 'rxjs';
import {SelectEntity, UnselectEntity} from 'app/blocks/store/actions/entity-actions';
import {Store} from '@ngxs/store';
import {FuseNavigation} from '@fuse/types';
import {adminNavigation} from 'app/navigation/admin-navigation';
import {Router} from '@angular/router';
import {RoleService} from 'app/blocks/service/api/role.service';
import {getDefaultEntitiesState} from 'app/blocks/store/states/entities-state';
import {currentDate, currentTime} from 'app/constants/ai';
import {HttpClient} from '@angular/common/http';
import {SERVER_API_URL} from 'app/app.constants';

@Injectable({
    providedIn: 'root'
})
export class AICommandService {
    private commands = [];
    public updateSubject = new Subject<EntityAction>();
    public isInline = {};

    constructor(
        private _store: Store,
        private _router: Router,
        public roleService: RoleService,
        private _http: HttpClient
    ) {
        for (const entry of Object.entries(getDefaultEntitiesState())) {
            this.isInline[entry[0]] = !entry[1].hasOwnProperty('entityView');
        }

        this.commands.push(
            new EntityAction({
                label: 'openEntity',
                isAIAction: true,
                identifyPromptResource: true,
                aiQuery: true,
                action: (response, resource) => {
                    if (response && response.length > 0 && response[0]?.id) {
                        resource.navigate.toView(response[0].id);
                    }
                }
            })
        );

        this.commands.push(
            new EntityAction({
                label: 'createEntity',
                isAIAction: true,
                identifyPromptResource: true,
                redirectOnResourceMismatch: '/',
                action: (resource) => {
                    if (this.isInline[resource.name]) {
                        this.updateCommand('createEntity', {
                            navigateAfter: resource.navigate.toListUrl()
                        });
                    } else {
                        this.updateCommand('createEntity', {
                            navigateAfter: resource.navigate.toNewUrl()
                        });
                    }
                }
            })
        );

        this.commands.push(
            new EntityAction({
                label: 'openScreen',
                isAIAction: true,
                aiCustomPromptLoadingText: 'Deciding Screen to open...',
                promptFormat: {
                    url: '',
                    title: ''
                },
                promptInstruction:
                    'The available screens are: ' +
                    JSON.stringify(this.getMenuList(adminNavigation)) +
                    '\n __IMPORTANT__ : Return the best matching __Screen URL__ and its __Screen Title__ from available screen that user trying to navigate to.',
                action: (response) => {
                    this._router.navigate([response.url]);
                }
            })
        );

        this.commands.push(
            new EntityAction({
                label: 'selectEntities',
                isAIAction: true,
                identifyPromptResource: true,
                showResponseInTable: true,
                aiQuery: true,
                action: (response, resource) => {
                    if (response && response.length > 0 && response[0]?.id) {
                        resource.navigate.toList();
                        const ids = response.map((item) => item.id);
                        this._store.dispatch(new SelectEntity(resource, ids));
                    }
                }
            })
        );

        this.commands.push(
            new EntityAction({
                label: 'query',
                isAIAction: true,
                aiQuery: true,
                showResponseInTable: true
            })
        );

        this.commands.push(
            new EntityAction({
                label: 'saveEntity',
                isAIAction: true
            })
        );

        this.commands.push(
            new EntityAction({
                label: 'saveAndApproveEntity',
                isAIAction: true
            })
        );

        this.commands.push(
            new EntityAction({
                label: 'deSelectEntities',
                identifyPromptResource: true,
                showResponseInTable: true,
                isAIAction: true,
                aiQuery: true,
                action: (response, resource) => {
                    if (response && response.length > 0 && response[0]?.id) {
                        resource.navigate.toList();
                        const ids = response.map((item) => item.id);
                        this._store.dispatch(new UnselectEntity(resource, ids));
                    }
                }
            })
        );

        this.commands.push(
            new EntityAction({
                label: 'optimizeAllRoute',
                isAIAction: true,
                useParamInAction: true,
                showCommandResultInTable: true,
                action: async (params) => {
                    const startDate = params.startDate;
                    const startTime = params.startTime;

                    if (!startDate || !startTime) {
                        return [{error: 'Please provide startDate and startTime'}];
                    }

                    const routes = await this.fireGet(`api/rdm/routes?date=${startDate}`);
                    if (!routes) {
                        return [{error: 'No routes found for the given date'}];
                    }

                    const routeIds = routes.body.map((route) => route.id);
                    const siteVisits = await this.fireGet(`api/rdm/site-visits?date=${startDate}&routeIds=${routeIds.join(',')}`);
                    if (!siteVisits) {
                        return [{error: 'No site visits found for the given date'}];
                    }

                    const siteVisitIds = siteVisits.body.map((siteVisit) => siteVisit.id);
                    const response = await this.firePost('api/rdm/route-planner/all-route-optimization', {
                        routeIds: routeIds,
                        startDate: startDate,
                        siteVisitIds: siteVisitIds
                    });

                    return this.routeOptimizationResult(response);
                },
                searchOptions: {
                    startDate: {
                        required: false,
                        repeatable: false,
                        function: 'startDate' + currentDate()
                    }
                }
            })
        );

        this.commands.push(
            new EntityAction({
                label: 'optimizeRoute',
                isAIAction: true,
                useParamInAction: true,
                showCommandResultInTable: true,
                action: async (params) => {
                    const startDate = params.startDate;
                    const startTime = params.startTime;
                    const routeIds = params.route?.id;

                    if (!startDate || !startTime || !routeIds) {
                        return [{error: 'Please provide startDate, startTime and route'}];
                    }

                    const siteVisits = await this.fireGet(`api/rdm/site-visits?date=${startDate}&routeIds=${routeIds}`);
                    if (!siteVisits) {
                        return [{error: 'No site visits found for the given date'}];
                    }

                    const siteVisitIds = siteVisits.map((siteVisit) => siteVisit.id);
                    const response = await this.firePost('api/rdm/route-planner/all-route-optimization', {
                        routeIds: [routeIds],
                        startDate: startDate,
                        siteVisitIds: siteVisitIds
                    });

                    return this.routeOptimizationResult(response);
                },
                searchOptions: {
                    startDate: {
                        required: false,
                        repeatable: false,
                        function: 'startDate' + currentDate()
                    },
                    startTime: {
                        required: false,
                        repeatable: false,
                        function: 'startTime' + currentTime()
                    },
                    route: {
                        required: true,
                        repeatable: false,
                        function: 'route()'
                    }
                }
            })
        );
    }

    public getCommands() {
        return this.commands;
    }

    public updateCommand(commandName: string, update: any) {
        for (let i = 0; i < this.commands.length; i++) {
            if (this.commands[i].label === commandName) {
                this.commands[i] = {...this.commands[i], ...update};
                this.updateSubject.next(this.commands[i]);
                break;
            }
        }
    }

    getMenuList = (nav: FuseNavigation[]) => {
        let menuList = [];
        let roleSettings = this.roleService.roleSettings.value;
        for (const item of nav) {
            if (item.children) {
                menuList = [...menuList, ...this.getMenuList(item.children)];
            } else {
                if (roleSettings[item.id] && roleSettings[item.id] === false) {
                    continue;
                }
                menuList.push({
                    title: item.title,
                    url: item.url
                });
            }
        }
        return menuList;
    };

    public fireGet(url: string): Promise<any> {
        return this._http.get(SERVER_API_URL + url).toPromise();
    }

    public firePost(url: string, body: any): Promise<any> {
        return this._http.post(SERVER_API_URL + url, body).toPromise();
    }

    routeOptimizationResult(response) {
        const result = [];
        if (response.optimizedRoutes.length > 0) {
            for (const optimizedRoute of response.optimizedRoutes) {
                result.push({
                    routeId: optimizedRoute.routeId,
                    siteVisitId: '',
                    failure: '',
                    failureReason: '',
                    cost: optimizedRoute.cost,
                    startRoutePoint: optimizedRoute.startRoutePoint.name,
                    endRoutePoint: optimizedRoute.endRoutePoint.name
                });
                for (const unoptimizedStops of optimizedRoute.unoptimizedStops) {
                    result.push({
                        routeId: optimizedRoute.routeId,
                        siteVisitId: unoptimizedStops.siteVisitId,
                        failure: unoptimizedStops.failure,
                        failureReason: unoptimizedStops.failureReason,
                        cost: '',
                        startRoutePoint: '',
                        endRoutePoint: ''
                    });
                }
            }
        } else {
            for (const unoptimizedStops of response.unoptimizedStops) {
                result.push({
                    routeId: response.routeId,
                    siteVisitId: unoptimizedStops.siteVisitId,
                    failure: unoptimizedStops.failure,
                    failureReason: unoptimizedStops.failureReason,
                    cost: '',
                    startRoutePoint: '',
                    endRoutePoint: ''
                });
            }
        }
        return result;
    }
}
