import moment from 'moment';
import {DiDate} from 'app/blocks/util/di-date';
import {DiTime} from 'app/blocks/util/di-time';

export class DiDateTime {
    constructor(date?: DiDate, time?: DiTime) {
        this.date = date ?? DiDate.currentDate();
        this.time = time ?? DiTime.currentTime();
    }

    public date: DiDate;
    public time: DiTime;

    public static newInstance(date?: any, time?: any) {
        if (date instanceof DiDateTime) {
            return new DiDateTime(date.date, date.time);
        }

        time = time ?? date;

        return new DiDateTime(DiDate.newInstance(date), DiTime.newInstance(new Date(time)));
    }

    public static currentDateTime() {
        return new DiDateTime();
    }

    static compare(date1: DiDateTime, date2: DiDateTime): number {
        let result = 0;
        if (date1 && date2) {
            result = DiDate.compare(date1.date, date2.date);
            if (result == 0) {
                result = DiTime.compare(date1.time, date2.time);
            }
        }
        return result;
    }

    public toString(): string {
        return `${this.date.toString()}T${this.time.toString()}`;
    }

    public toJSON(): string {
        return `${this.date.toJSON()}T${this.time.toJSON()}`;
    }

    public getDate(): number {
        return this.date.date;
    }

    public setMinutes(minutes: number): void {
        this.time.min = minutes;
    }

    public setHours(hours: number): void {
        this.time.hour = hours;
    }

    public asDate(): Date {
        const date = this.date.asDate();
        date.setHours(this.time.hour);
        date.setMinutes(this.time.min);
        return date;
    }

    public asMoment(): moment.Moment {
        return moment(this.asDate());
    }

    public format(dateFormat: string): string {
        return this.asMoment().format(dateFormat);
    }

    public formatIgnoreZones(dateFormat: string): string {
        return this.asMoment().utc().format(dateFormat);
    }

    public isToday(): boolean {
        return this.date.isToday();
    }

    public isBefore(dateToCompare: DiDateTime): boolean {
        return DiDateTime.compare(this, dateToCompare) < 0;
    }

    public isAfter(dateToCompare: DiDateTime): boolean {
        return DiDateTime.compare(this, dateToCompare) > 0;
    }
}
