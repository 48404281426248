import moment from 'moment';
import {DiDateTime} from 'app/blocks/util/di-date-time';
import {DiTime} from 'app/blocks/util/di-time';

export class DiDate {
    constructor(dateObj?: {year: number; month: number; date: number}) {
        this.year = dateObj.year;
        this.month = dateObj.month;
        this.date = dateObj.date;
    }
    year: number;
    month: number;
    date: number;

    static newInstance(obj: any): DiDate {
        let instance = null;
        if (obj) {
            if (moment.isMoment(obj)) {
                instance = new DiDate({
                    year: obj.year(),
                    month: obj.month() + 1,
                    date: obj.date()
                });
            } else if (typeof obj === 'string') {
                if (obj.includes('T')) {
                    obj = obj.split('T')[0];
                }
                const dateString = obj.split('-');
                instance = new DiDate({
                    year: +dateString[0],
                    month: +dateString[1],
                    date: +dateString[2]
                });
            } else if (obj instanceof Date) {
                instance = new DiDate({
                    year: obj.getFullYear(),
                    month: obj.getMonth() + 1,
                    date: obj.getDate()
                });
            } else if (obj instanceof DiDate) {
                instance = obj;
            }
        }
        return instance;
    }

    static currentDate(): DiDate {
        return DiDate.newInstance(moment());
    }

    static currentTime(): string {
        return moment().format('HH:mm');
    }

    static inUKDateFormat(date: string): string {
        if (date) {
            const [year, month, day] = date.split('-');
            return `${day}/${month}/${year}`;
        }
        return null;
    }

    static compare(date1: DiDate, date2: DiDate): number {
        let result = 0;
        if (date1 && date2) {
            result = date1.year - date2.year;
            if (result == 0) {
                result = date1.month - date2.month;
                if (result == 0) {
                    result = date1.date - date2.date;
                }
            }
        }
        return result;
    }

    static lastDayOfCurrentMonth(): DiDate {
        return new DiDate({
            year: moment().year(),
            month: moment().month() + 1,
            date: moment().daysInMonth()
        });
    }

    // method to get the date of the last occurence of a specific day of the week, default is 0- Sunday
    static lastDayOfWeek(dayOfWeek = 0, date: DiDate = this.currentDate()): DiDate {
        const today: moment.Moment = date.asMoment();
        const currentWeekday: number = today.day();
        let daysToGoBack: number = Math.abs(currentWeekday - dayOfWeek);
        if (currentWeekday <= dayOfWeek) {
            daysToGoBack = 7 - daysToGoBack;
        }
        return this.newInstance(today.subtract(daysToGoBack, 'days'));
    }

    toString(): string {
        return `${this.year}-${this.padLeftZero(this.month, 2)}-${this.padLeftZero(this.date, 2)}`;
    }

    toJSON(): string {
        return this.toString();
    }

    asMoment(): moment.Moment {
        return moment({
            year: this.year,
            month: this.month - 1,
            date: this.date
        });
    }

    asDate(): Date {
        return new Date(this.year, this.month - 1, this.date);
    }

    addDays(numberOfDays: number): DiDate {
        return DiDate.newInstance(this.asMoment().add(numberOfDays, 'day'));
    }

    private padLeftZero(numberToPad: number, noOfDigits: number): string {
        const str = '' + numberToPad;
        return ('0'.repeat(noOfDigits) + str).substring(str.length);
    }

    getDate(): number {
        return this.date;
    }

    getMonth(): number {
        return this.month;
    }

    getFullYear(): number {
        return this.year;
    }

    isToday(): boolean {
        const today: DiDate = DiDate.currentDate();
        return this.year == today.year && this.month == today.month && this.date == today.date;
    }

    atTime(time: DiTime): DiDateTime {
        return new DiDateTime(this, time);
    }
}
