import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {FuseNavigationService} from '@fuse/components/navigation/navigation.service';
import {FuseNavigation} from '@fuse/types';
import {adminFeature} from 'app/constants/role-settings/admin';
import {customerFeature} from 'app/constants/role-settings/customer';
import {userFeature} from 'app/constants/role-settings/user';
import {adminNavigation} from 'app/navigation/admin-navigation';
import {customerNavigation} from 'app/navigation/customer-navigation';
import {navigation} from 'app/navigation/navigation';
import {ROLE_SETTINGS, SERVER_API_URL} from 'app/app.constants';
import {IRole, Role} from 'app/blocks/model/role.model';
import {Resource} from 'app/constants/resource';
import {upperCase} from 'lodash';
import EntityCrudService from 'app/blocks/service/api/entity-service';
import {BehaviorSubject, map, Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class RoleService extends EntityCrudService<IRole> {
    roleSettings = new BehaviorSubject<any>({});
    constructor(
        http: HttpClient,
        private _fuseNavigationService: FuseNavigationService
    ) {
        super(http, Resource.ROLES, (obj?) => new Role(obj));
    }

    getRole = (id: number): Promise<any> => {
        return this.http.get<any>(SERVER_API_URL + `api/roles/${id}`).toPromise();
    };

    getRoleByName = (roleName: string): Promise<any> => {
        return this.http.get<any>(SERVER_API_URL + `api/roles/name/${roleName}`).toPromise();
    };

    getRoleDefaults = (authorityName: string): Promise<any> => {
        return this.http.get<any>(SERVER_API_URL + `api/roles/role-defaults/authority/${authorityName}`).toPromise();
    };

    getRoleByAuthority = (authorityName: string): Promise<any[]> => {
        return this.http.get<any>(SERVER_API_URL + `api/roles/authority/${authorityName}`).toPromise();
    };

    deleteRole = (id: number): Promise<any> => {
        return this.http.delete<any>(SERVER_API_URL + `api/roles/${id}`).toPromise();
    };

    saveRole = (role: any): Promise<any> => {
        return this.http.post<any>(SERVER_API_URL + 'api/roles', role).toPromise();
    };

    getTenantMenu = (): Promise<any> => {
        return this.http.get<any>(SERVER_API_URL + 'api/roles/settings').toPromise();
    };

    getMenuByAuthority = (authority: string, filters: any): FuseNavigation[] => {
        switch (authority) {
            case 'ROLE_ADMIN':
                return this.addVisibility(adminNavigation.concat(adminFeature), filters);
            case 'ROLE_CUSTOMER':
                return this.addVisibility(customerNavigation.concat(customerFeature), filters);
            default:
                return this.addVisibility(navigation.concat(userFeature), filters);
        }
    };

    addVisibility = (nav: FuseNavigation[], filters: any) => {
        const menuList = [];
        for (const item of nav) {
            const menu = {
                id: item.id,
                visible: filters != null && filters[item.id] != null ? filters[item.id] : true,
                type: upperCase(item.type),
                children: item.children ? this.addVisibility(item.children, filters) : null,
                translationKey: item.translate
            };
            menuList.push(menu);
        }
        return menuList;
    };

    updateNavigation = (): void => {
        this.getTenantMenu().then((filters) => {
            if (filters) {
                Object.getOwnPropertyNames(filters).forEach((key: any) => {
                    this._fuseNavigationService.updateNavigationItem(key, {
                        hidden: !filters[key]
                    });
                });
            }
        });
    };

    getTenantProfile = (): Promise<any> => {
        return this.http.get<any>(SERVER_API_URL + 'api/roles/profiles').toPromise();
    };

    subscribeToShowSetting = (key): Observable<any> => {
        return this.roleSettings.pipe(
            map((settings) => {
                if (settings) {
                    if (settings[key + '-show'] != null) {
                        return settings[key + '-show'];
                    }
                    return true;
                }
                return true;
            })
        );
    };

    subscribeToEnableSetting = (key): Observable<any> => {
        return this.roleSettings.pipe(
            map((settings) => {
                if (settings) {
                    if (settings[key + '-enable'] != null) {
                        return settings[key + '-enable'];
                    }
                    return true;
                }
                return true;
            })
        );
    };

    showSetting = (key): boolean => {
        if (this.roleSettings.value) {
            if (this.roleSettings.value[key + '-show'] != null) {
                return this.roleSettings.value[key + '-show'];
            }
            return true;
        }
        return true;
    };

    enableSetting = (key): boolean => {
        if (this.roleSettings.value) {
            if (this.roleSettings.value[key + '-enable'] != null) {
                return this.roleSettings.value[key + '-enable'];
            }
            return true;
        }
        return true;
    };
}
