import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {ILocationPoint, LocationPoint} from 'app/blocks/model/location-point.model';
import {ILocationPointAwaitingIntegration} from 'app/blocks/model/location-point-awaiting-integration.model';
import {Resource} from 'app/constants/resource';

import EntityCrudService from 'app/blocks/service/api/entity-service';
import {SERVER_API_URL} from 'app/app.constants';
import {Observable} from 'rxjs';
import {createRequestOption, createRequestOption2} from 'app/blocks/util/request-util';

@Injectable({
    providedIn: 'root'
})
export class LocationPointService extends EntityCrudService<ILocationPoint> {
    constructor(http: HttpClient) {
        super(http, Resource.LOCATION_POINTS, (obj?) => new LocationPoint(obj));
    }

    sendDemoEmailForLocationPoint = (locationPointId: number): Observable<any> => {
        const REQUEST_URI = `${SERVER_API_URL}api/location-points/${locationPointId}/demo-email`;
        return this.http.get(REQUEST_URI, {});
    };

    getSimilarLocationPoints = (locationPoint: ILocationPoint): Observable<any> => {
        const REQUEST_URI = `${SERVER_API_URL}api/location-points/similar-points`;
        return this.http.get(REQUEST_URI, {
            params: {
                size: 10,
                locationName: locationPoint.name,
                line1: locationPoint.contactInfo.address.line1,
                postCode: locationPoint.contactInfo.address.postCodeZip,
                latitude: locationPoint.contactInfo.address.latitude || '',
                longitude: locationPoint.contactInfo.address.longitude || ''
            }
        });
    };

    searchPostCodes = (term: string) => {
        const REQUEST_URI = `${SERVER_API_URL}api/_search/post-codes`;
        return this.http.get<[{postCodeZip: string}]>(REQUEST_URI, {
            params: {
                postCode: term
            }
        });
    };

    getPointsWithMissingData = (): Observable<any> => {
        const REQUEST_URI = `${SERVER_API_URL}api/location-points/missing-data`;
        return this.http.get(REQUEST_URI, {
            params: {
                size: 80
            }
        });
    };

    getLocationPointsWithOrders = (orderIds: any, filterType = 'DELIVERIES_FOR_PLANNING'): Observable<any> => {
        const REQUEST_PARAMS = createRequestOption({orderIds, filterType});
        const REQUEST_URI = SERVER_API_URL + 'api/location-points/orders';
        return this.http.get(REQUEST_URI, {
            params: REQUEST_PARAMS
        });
    };

    putGeofence = (locationPointId: number, geofenceCoordinates: any, selectedVehicleIds: number[] | null): Promise<any> => {
        const REQUEST_URI = SERVER_API_URL + `api/location-points/${locationPointId}/geofence`;
        const vehicles = selectedVehicleIds != null ? selectedVehicleIds.map((vehicleId) => ({id: vehicleId})) : null;
        const requestBody = {
            polygonBoundary:
                geofenceCoordinates != null
                    ? {
                          type: 'Polygon',
                          coordinates: [geofenceCoordinates]
                      }
                    : null,
            vehicleDTOSet: vehicles
        };
        return this.http.put<any>(REQUEST_URI, requestBody).toPromise();
    };

    getGeofence = (locationPointId: number): Promise<any> => {
        const REQUEST_URI = SERVER_API_URL + `api/location-points/${locationPointId}/geofence`;
        return this.http.get(REQUEST_URI).toPromise();
    };

    postGeofenceVehicles = (locationPointId: number, selectedVehicleIds: number[] | null): Promise<any> => {
        const REQUEST_URI = SERVER_API_URL + `api/location-points/${locationPointId}/geofence/vehicles`;
        const vehicles = selectedVehicleIds != null ? selectedVehicleIds.map((vehicleId) => ({id: vehicleId})) : null;
        const requestBody = {vehicleDTOSet: vehicles};
        return this.http.post<any>(REQUEST_URI, requestBody).toPromise();
    };

    getIntegrations = (locationPointId: number): Promise<any[]> => {
        const REQUEST_URI = SERVER_API_URL + `api/location-points/${locationPointId}/integrations`;
        return this.http.get<ILocationPointAwaitingIntegration[]>(REQUEST_URI).toPromise();
    };

    deleteIntegrations = (locationPointId: number, integrationIds: number[]): Promise<any> => {
        const REQUEST_URI = SERVER_API_URL + `api/location-points/${locationPointId}/integrations`;
        return this.http.delete<any>(REQUEST_URI, {body: integrationIds}).toPromise();
    };

    updateAddressCoordinates = (locationPointId: number, locationPointCoordinates: {lat: number; lng: number}): Promise<any> => {
        const REQUEST_URI = SERVER_API_URL + `api/location-points/${locationPointId}/address-coordinates`;
        const requestBody = {
            latitude: locationPointCoordinates.lat,
            longitude: locationPointCoordinates.lng
        };
        return this.http.put<any>(REQUEST_URI, requestBody).toPromise();
    };

    activateResource = (id: number): Promise<any> => {
        return this.http
            .put<any>(`${SERVER_API_URL}api/activate-point/${id}`, null, {
                observe: 'response'
            })
            .toPromise();
    };

    deactivatePoint = (id: number): Observable<any> => {
        return this.http.delete<any>(`${SERVER_API_URL}api/location-points/${id}`);
    };

    approvePoint = (id: number): Promise<any> => {
        const REQUEST_URI = SERVER_API_URL + `api/location-points/approve/${id}`;
        console.log(REQUEST_URI);
        return this.http.post<any>(REQUEST_URI, {}).toPromise();
    };

    getLocationPointLite = (type?: string): Observable<any> => {
        const params = createRequestOption2({type: type});

        const REQUEST_URI = `${SERVER_API_URL}api/location-points-lite`;
        return this.http.get(REQUEST_URI, {params: params});
    };

    updateInvalidLatLongs = (): Promise<any> => {
        const REQUEST_URI = SERVER_API_URL + `api/location-points/invalid-lat-lng`;
        return this.http.post<any>(REQUEST_URI, {}).toPromise();
    };

    activateResources = (ids: any): Promise<any> => {
        return this.http
            .post<any>(`${SERVER_API_URL}api/location-points/activate-bulk`, ids, {
                observe: 'response'
            })
            .toPromise();
    };

    deactivateResources = (ids: any): Promise<any> => {
        return this.http
            .post<any>(`${SERVER_API_URL}api/location-points/deactivate-bulk`, ids, {
                observe: 'response'
            })
            .toPromise();
    };
}
