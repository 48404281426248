import {IDriver} from 'app/blocks/model/driver.model';
import {SERVER_API_URL} from 'app/app.constants';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {IVehicle, Vehicle} from 'app/blocks/model/vehicle.model';
import {Resource} from 'app/constants/resource';

import EntityCrudService from 'app/blocks/service/api/entity-service';
import {ErrorSeverity, createRequestContext} from 'app/blocks/util/request-util';
import {FileType} from 'app/blocks/model/hm-file.model';

@Injectable({
    providedIn: 'root'
})
export class VehicleService extends EntityCrudService<IVehicle> {
    constructor(http: HttpClient) {
        super(http, Resource.VEHICLES, (obj?) => new Vehicle(obj));
    }

    public getAllVehicles = (vehicleFilterModeId?: string, vehicleFilterModeOptionId?: number): Promise<any[]> => {
        let params = new HttpParams();
        params = params.append('driverFilterModeId', vehicleFilterModeId?.toString());
        params = params.append('driverFilterModeOptionId', vehicleFilterModeOptionId?.toString());

        return this.http.get<IVehicle[]>(`${SERVER_API_URL}api/vehicles`, {params: params}).toPromise();
    };

    getVehiclesComingIn(
        legStatuses?: string,
        customerId?: number,
        orderNumber?: number,
        runId?: number,
        driverId?: number,
        vehicleId?: number,
        trailerId?: number,
        startDate?: string[],
        endDate?: string[],
        businessTypeIds?: string,
        trafficAreaIds?: string,
        controlAreaIds?: string,
        errorSeverity = ErrorSeverity.ERROR
    ): Promise<any> {
        const REQUEST_URI = SERVER_API_URL + 'api/vehicles/coming-in';
        let REQUEST_PARAMS = new HttpParams().set('businessTypeIds', businessTypeIds).set('trafficAreaIds', trafficAreaIds);
        if (controlAreaIds && controlAreaIds.length > 0) {
            REQUEST_PARAMS = REQUEST_PARAMS.set('controlAreaIds', controlAreaIds[0]);
        }
        if (startDate && startDate.length === 2) {
            REQUEST_PARAMS = REQUEST_PARAMS.set('startDateFrom', startDate[0]).set('startDateTo', startDate[1]);
        }
        if (endDate && endDate.length === 2) {
            REQUEST_PARAMS = REQUEST_PARAMS.set('endDateFrom', endDate[0]).set('endDateTo', endDate[1]);
        }
        if (legStatuses) {
            REQUEST_PARAMS = REQUEST_PARAMS.set('legStatuses', legStatuses);
        }
        if (customerId) {
            REQUEST_PARAMS = REQUEST_PARAMS.set('customer', customerId);
        }
        if (orderNumber) {
            REQUEST_PARAMS = REQUEST_PARAMS.set('orderNumber', orderNumber);
        }
        if (runId) {
            REQUEST_PARAMS = REQUEST_PARAMS.set('runId', runId);
        }
        if (driverId) {
            REQUEST_PARAMS = REQUEST_PARAMS.set('driverId', driverId);
        }
        if (vehicleId) {
            REQUEST_PARAMS = REQUEST_PARAMS.set('vehicleId', vehicleId);
        }
        if (trailerId) {
            REQUEST_PARAMS = REQUEST_PARAMS.set('trailerId', trailerId);
        }
        return this.http
            .get<any>(REQUEST_URI, {
                params: REQUEST_PARAMS,
                context: createRequestContext({errorSeverity})
            })
            .toPromise();
    }

    public checkMot = (vehicleId: number): Promise<any> => {
        // const REQUEST_PARAMS = new HttpParams().set('vehicleId',vehicleId.toString());
        return this.http.get<any>(`${SERVER_API_URL}api/vehicle/check-mot/${vehicleId}`).toPromise();
    };

    getAllVehiclesForPlanning(userId: any, teamId: number): Promise<any> {
        let params = new HttpParams();
        if (userId) {
            params = params.append('userId', userId);
        }
        if (teamId) {
            params = params.append('teamId', teamId);
        }

        return this.http
            .get<IVehicle[]>(`${SERVER_API_URL}api/vehicles-resource`, {
                params: params
            })
            .toPromise();
    }

    getDefaultDrivers(vehicleId: any): Promise<any> {
        const params = new HttpParams();
        return this.http
            .get<IDriver[]>(`${SERVER_API_URL}api/vehicles/${vehicleId}/default-drivers`, {
                params: params
            })
            .toPromise();
    }

    activateResource = (id: number): Promise<any> => {
        return this.http
            .put<any>(`${SERVER_API_URL}api/activate-vehicle/${id}`, null, {
                observe: 'response'
            })
            .toPromise();
    };

    deleteInactiveResource = (): Promise<any> => {
        const REQUEST_URI = `${SERVER_API_URL}api/vehicle/delete-inactive`;
        return this.http.delete(REQUEST_URI).toPromise();
    };

    getAvailableVehicles = (vehicleClassId: number, startDate, startTime, endDate, endTime): Promise<number> => {
        let params = new HttpParams();
        params = params.append('startDate', startDate);
        params = params.append('startTime', startTime);
        params = params.append('endDate', endDate);
        params = params.append('endTime', endTime);
        return this.http.get<number>(`${SERVER_API_URL}api/vehicles/available/${vehicleClassId}`, {params: params}).toPromise();
    };

    getVehicleByGpsUnit = (gpsUnit: string): Promise<Vehicle> => {
        return this.http.get<Vehicle>(`${SERVER_API_URL}api/vehicles/gpsUnit/${gpsUnit}`).toPromise();
    };

    downloadFile = (vehicleId: number, hmFileId: number): any => {
        const REQUEST_URI = `${SERVER_API_URL}api/vehicles/${vehicleId}/docs/${hmFileId}`;
        return this.http.get(REQUEST_URI, {responseType: 'arraybuffer'});
    };

    uploadFile = (label: string, file: File, base64File: string, vehicleId: number, fileType: string): Promise<any> => {
        const fd = new FormData();
        if (base64File) {
            fd.append('base64File', base64File);
        } else {
            fd.append('file', file, file.name);
        }
        fd.append('label', label);
        if (fileType) {
            fd.append('fileType', fileType);
        } else {
            fd.append('fileType', FileType.OTHERS);
        }
        return this.http.post<any>(`${SERVER_API_URL}api/vehicles/${vehicleId}/docs`, fd).toPromise();
    };

    activateResources = (ids: any): Promise<any> => {
        return this.http
            .post<any>(`${SERVER_API_URL}api/vehicles/activate-bulk`, ids, {
                observe: 'response'
            })
            .toPromise();
    };

    deactivateResources = (ids: any): Promise<any> => {
        return this.http
            .post<any>(`${SERVER_API_URL}api/vehicles/deactivate-bulk`, ids, {
                observe: 'response'
            })
            .toPromise();
    };
}
