import {IBaseEntity, BaseEntity} from 'app/blocks/model/base-entity.model';

export enum FileType {
    CUSTOM_UPLOADED_DOC = 'CUSTOM_UPLOADED_DOC',
    CUSTOMER_OWN_PAPERWORK = 'CUSTOMER_OWN_PAPERWORK',
    BOOKING_FORM = 'BOOKING_FORM',
    ONSITE_IMAGES = 'ONSITE_IMAGES',
    COLLECTION_SIGNATURE = 'COLLECTION_SIGNATURE',
    DELIVERY_SIGNATURE = 'DELIVERY_SIGNATURE',
    OTHERS = 'OTHERS',
    PMI = 'PMI',
    SERVICE_DOCUMENT = 'SERVICE_DOCUMENT'
}
export interface IHmFile extends IBaseEntity {
    id?: number;
    name?: string;
    path?: string;
    fileName?: string;
    type?: string;
    originalFileName: string;
    label?: string;
    multipartFile?: File;
    sequence?: number;
    fileType?: string;
    fileLink?: string;
    status?: string;
    customer?: string;
    collectionPoint?: string;
    deliveryPoint?: string;
    orders?: any[];
    latitude?: number;
    longitude?: number;
    createdAt?: Date;
    uploadedBy?: string;
}
export class HmFile extends BaseEntity implements IHmFile {
    public id?: number;
    public fileName?: string;
    public path?: string;
    public type?: string;
    public originalFileName: string;
    public name?: string;
    public label?: string;
    public multipartFile?: File;
    public sequence?: number;
    public fileType?: string;
    public fileLink?: string;
    public status?: string;
    public customer?: string;
    public collectionPoint?: string;
    public deliveryPoint?: string;
    public orders?: any[];
    public latitude?: number;
    public longitude?: number;
    public createdAt?: Date;
    public uploadedBy?: string;

    constructor(file?) {
        super();
        if (file) {
            this.id = file.id;
            this.path = file.path;
            this.fileName = file.path ? file.path.replace(/^.*(\\|\/|\:)/, '') : null;
            this.type = file.type;
            this.name = file.name;
            this.label = file.label;
            this.sequence = file.sequence;
            this.fileType = file.fileType;
            this.fileLink = file.fileLink;
            this.status = file.status;
            this.customer = file.customer;
            this.collectionPoint = file.collectionPoint;
            this.deliveryPoint = file.deliveryPoint;
            this.orders = file.orders;
            this.latitude = file.latitude;
            this.longitude = file.longitude;
            this.createdAt = file.createdAt;
            this.originalFileName = file.originalFileName;
            this.uploadedBy = file.uploadedBy;
        }
    }
    get discriminator(): any {
        return this.id;
    }
    clone(): HmFile {
        return new HmFile(this);
    }
}
