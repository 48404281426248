import {HttpClient, HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {SERVER_API_URL} from 'app/app.constants';
import {createRequestOption} from 'app/blocks/util/request-util';
import {Resource} from 'app/constants/resource';

import EntityCrudService from 'app/blocks/service/api/entity-service';
import {ReportData, IReportData} from 'app/blocks/model/report.model';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ReportService extends EntityCrudService<IReportData> {
    constructor(http: HttpClient) {
        super(http, Resource.REPORTS, (obj?) => new ReportData(obj));
    }

    getBasicReport(params: any): Promise<HttpResponse<any>> {
        const options = createRequestOption(params);
        return this.http
            .get(SERVER_API_URL + 'api/reports', {
                params: options,
                observe: 'response'
            })
            .toPromise();
    }

    exportReport(params: any): Promise<any> {
        const options = createRequestOption(params);
        return this.http
            .get(SERVER_API_URL + 'api/reports/export', {
                responseType: 'arraybuffer',
                params: options
            })
            .toPromise();
    }

    searchRates(req?: any): Observable<any[]> {
        const options = createRequestOption(req);
        return this.http.get<any[]>(SERVER_API_URL + 'api/reports/rates', {
            params: options
        });
    }

    getClientRevenueReport(params: any): Promise<any> {
        const options = createRequestOption(params);
        return this.http
            .get(SERVER_API_URL + 'api/reports/client-revenue', {
                params: options
            })
            .toPromise();
    }

    exportClientRevenueReport(params: any): Promise<any> {
        const options = createRequestOption(params);
        return this.http
            .get(SERVER_API_URL + 'api/reports/client-revenue/export', {
                responseType: 'arraybuffer',
                params: options
            })
            .toPromise();
    }

    getDriverWorkReport(params: any): Promise<any> {
        const options = createRequestOption(params);
        return this.http.get(SERVER_API_URL + 'api/reports/driver-work', {params: options}).toPromise();
    }

    exportDriverWorkReport(params: any): Promise<any> {
        const options = createRequestOption(params);
        return this.http
            .get(SERVER_API_URL + 'api/reports/driver-work/export', {
                responseType: 'arraybuffer',
                params: options
            })
            .toPromise();
    }

    getRevenueReport(params: any): Promise<HttpResponse<any>> {
        const options = createRequestOption(params);
        return this.http
            .get(SERVER_API_URL + 'api/reports/revenue-report', {
                params: options,
                observe: 'response'
            })
            .toPromise();
    }

    exportRevenueReport(params: any): Promise<any> {
        const options = createRequestOption(params);
        return this.http
            .get(SERVER_API_URL + 'api/reports/revenue-report/export', {
                responseType: 'arraybuffer',
                params: options,
                observe: 'response'
            })
            .toPromise();
    }

    getClientWorkReport(params: any): Promise<any> {
        const options = createRequestOption(params);
        return this.http.get(SERVER_API_URL + 'api/reports/client-work', {params: options}).toPromise();
    }

    exportClientWorkReport(params: any): Promise<any> {
        const options = createRequestOption(params);
        return this.http
            .get(SERVER_API_URL + 'api/reports/client-work/export', {
                responseType: 'arraybuffer',
                params: options
            })
            .toPromise();
    }

    getCompletedRunReport(params: any): Promise<any> {
        const options = createRequestOption(params);
        return this.http
            .get(SERVER_API_URL + 'api/reports/completed-run', {
                params: options
            })
            .toPromise();
    }

    exportCompletedRunReport(params: any): Promise<any> {
        const options = createRequestOption(params);
        return this.http
            .get(SERVER_API_URL + 'api/reports/completed-run/export', {
                responseType: 'arraybuffer',
                params: options
            })
            .toPromise();
    }

    mailCompletedRunReport(params: any): Promise<any> {
        const options = createRequestOption(params);
        return this.http
            .get(SERVER_API_URL + 'api/reports/completed-run/mail', {
                params: options
            })
            .toPromise();
    }

    getCompletedReportPdf = (filters, preview = true): Promise<any> => {
        const options = createRequestOption(filters);
        const type = preview === true ? 'blob' : 'arraybuffer';
        const REQUEST_URI = `${SERVER_API_URL}api/reports/completed-run/pdf`;
        return this.http
            .get<any>(REQUEST_URI, {
                responseType: type as 'json',
                params: options
            })
            .toPromise();
    };

    getAgencyDriverReport(params: any): Promise<any> {
        const options = createRequestOption(params);
        return this.http
            .get(SERVER_API_URL + 'api/reports/agency-driver', {
                params: options
            })
            .toPromise();
    }

    exportAgencyDriverReport(params: any): Promise<any> {
        const options = createRequestOption(params);
        return this.http
            .get(SERVER_API_URL + 'api/reports/agency-driver/export', {
                responseType: 'arraybuffer',
                params: options
            })
            .toPromise();
    }

    getAllRunsForClientsReport(params: any): Promise<any> {
        const options = createRequestOption(params);
        return this.http
            .get(SERVER_API_URL + 'api/reports/all-runs-for-clients', {
                params: options
            })
            .toPromise();
    }

    exportAllRunsForClientsReport(params: any): Promise<any> {
        const options = createRequestOption(params);
        return this.http
            .get(SERVER_API_URL + 'api/reports/all-runs-for-clients/export', {
                responseType: 'arraybuffer',
                params: options
            })
            .toPromise();
    }

    getSubcontractHistoryReport(params: any): Promise<any> {
        const options = createRequestOption(params);
        return this.http
            .get(SERVER_API_URL + 'api/reports/subcontract-history', {
                params: options
            })
            .toPromise();
    }

    exportSubcontractHistoryReport(params: any): Promise<any> {
        const options = createRequestOption(params);
        return this.http
            .get(SERVER_API_URL + 'api/reports/subcontract-history/export', {
                responseType: 'arraybuffer',
                params: options
            })
            .toPromise();
    }

    mailSubcontractHistoryReport(params: any): Promise<any> {
        const options = createRequestOption(params);
        return this.http
            .get(SERVER_API_URL + 'api/reports/subcontract-history/mail', {
                params: options
            })
            .toPromise();
    }

    getSubcontractHistoryReportPdf = (filters, preview = true): Promise<any> => {
        const options = createRequestOption(filters);
        const type = preview === true ? 'blob' : 'arraybuffer';
        const REQUEST_URI = `${SERVER_API_URL}api/reports/subcontract-history/pdf`;
        return this.http
            .get<any>(REQUEST_URI, {
                responseType: type as 'json',
                params: options
            })
            .toPromise();
    };

    getPricingReport(params: any): Promise<any> {
        const options = createRequestOption(params);
        return this.http.get(SERVER_API_URL + 'api/reports/pricing', {params: options}).toPromise();
    }

    exportPricingReport(params: any): Promise<any> {
        const options = createRequestOption(params);
        return this.http
            .get(SERVER_API_URL + 'api/reports/pricing/export', {
                responseType: 'arraybuffer',
                params: options
            })
            .toPromise();
    }

    getPricingReportPdf = (filters, preview = true): Promise<any> => {
        const options = createRequestOption(filters);
        const type = preview === true ? 'blob' : 'arraybuffer';
        const REQUEST_URI = `${SERVER_API_URL}api/reports/pricing/pdf`;
        return this.http
            .get<any>(REQUEST_URI, {
                responseType: type as 'json',
                params: options
            })
            .toPromise();
    };

    getEmptyRunningReport(params: any): Promise<any> {
        const options = createRequestOption(params);
        return this.http
            .get(SERVER_API_URL + 'api/reports/empty-running', {
                params: options
            })
            .toPromise();
    }

    exportEmptyRunningReport(params: any): Promise<any> {
        const options = createRequestOption(params);
        return this.http
            .get(SERVER_API_URL + 'api/reports/empty-running/export', {
                responseType: 'arraybuffer',
                params: options
            })
            .toPromise();
    }

    exportEmptyRunningReportSummary(params: any): Promise<any> {
        const options = createRequestOption(params);
        return this.http
            .get(SERVER_API_URL + 'api/reports/empty-running-summary/export', {
                responseType: 'arraybuffer',
                params: options
            })
            .toPromise();
    }

    getEmptyRunningReportPdf = (filters, preview = true): Promise<any> => {
        const options = createRequestOption(filters);
        const type = preview === true ? 'blob' : 'arraybuffer';
        const REQUEST_URI = `${SERVER_API_URL}api/reports/empty-running/pdf`;
        return this.http
            .get<any>(REQUEST_URI, {
                responseType: type as 'json',
                params: options
            })
            .toPromise();
    };

    getExtraRevenueReport(params: any): Promise<any> {
        const options = createRequestOption(params);
        return this.http
            .get(SERVER_API_URL + 'api/reports/extras-revenue', {
                params: options
            })
            .toPromise();
    }

    exportExtraRevenueReport(params: any): Promise<any> {
        const options = createRequestOption(params);
        return this.http
            .get(SERVER_API_URL + 'api/reports/extras-revenue/export', {
                responseType: 'arraybuffer',
                params: options
            })
            .toPromise();
    }

    getExtraRevenueReportPdf = (filters, preview = true): Promise<any> => {
        const options = createRequestOption(filters);
        const type = preview === true ? 'blob' : 'arraybuffer';
        const REQUEST_URI = `${SERVER_API_URL}api/reports/extras-revenue/pdf`;
        return this.http
            .get<any>(REQUEST_URI, {
                responseType: type as 'json',
                params: options
            })
            .toPromise();
    };

    mailExtraRevenueReport(params: any): Promise<any> {
        const options = createRequestOption(params);
        return this.http
            .get(SERVER_API_URL + 'api/reports/extras-revenue/mail', {
                params: options
            })
            .toPromise();
    }

    getResourceWorkReport(params: any): Promise<any> {
        const options = createRequestOption(params);
        return this.http.get(SERVER_API_URL + 'api/reports/resource-work', {params: options}).toPromise();
    }

    getResourceWorkReportSummary(params: any): Promise<any> {
        const options = createRequestOption(params);
        return this.http.get(SERVER_API_URL + 'api/reports/resource-work-summary', {params: options}).toPromise();
    }

    exportResourceWorkReport(params: any): Promise<any> {
        const options = createRequestOption(params);
        return this.http
            .get(SERVER_API_URL + 'api/reports/resource-work/export', {
                responseType: 'arraybuffer',
                params: options
            })
            .toPromise();
    }

    getSubcontractProfitabilityReport(params: any): Promise<any> {
        const options = createRequestOption(params);
        return this.http
            .get(SERVER_API_URL + 'api/reports/subcontract-profitability', {
                params: options
            })
            .toPromise();
    }

    exportSubcontractProfitabilityReport(params: any): Promise<any> {
        const options = createRequestOption(params);
        return this.http
            .get(SERVER_API_URL + 'api/reports/subcontract-profitability/export', {
                responseType: 'arraybuffer',
                params: options
            })
            .toPromise();
    }

    getAcknowledgeReport(params: any): Promise<any> {
        const options = createRequestOption(params);
        return this.http
            .get(SERVER_API_URL + 'api/reports/acknowledge-report', {
                params: options
            })
            .toPromise();
    }

    exportAcknowledgeReport(params: any): Promise<any> {
        const options = createRequestOption(params);
        return this.http
            .get(SERVER_API_URL + 'api/reports/acknowledge-report/export', {
                responseType: 'arraybuffer',
                params: options
            })
            .toPromise();
    }

    getReturnRefusalRedeliveryReport(params: any): Promise<any> {
        const options = createRequestOption(params);
        return this.http
            .get(SERVER_API_URL + 'api/reports/return-refusal-redelivery', {
                params: options
            })
            .toPromise();
    }

    exportReturnRefusalRedeliveryReport(params: any): Promise<any> {
        const options = createRequestOption(params);
        return this.http
            .get(SERVER_API_URL + 'api/reports/return-refusal-redelivery/export', {
                responseType: 'arraybuffer',
                params: options
            })
            .toPromise();
    }

    mailReturnRefusalRedeliveryReport(params: any): Promise<any> {
        const options = createRequestOption(params);
        return this.http
            .get(SERVER_API_URL + 'api/reports/return-refusal-redelivery/mail', {
                params: options
            })
            .toPromise();
    }

    getNonComplianceReport(params: any): Promise<any> {
        const options = createRequestOption(params);
        return this.http
            .get(SERVER_API_URL + 'api/reports/non-compliance', {
                params: options
            })
            .toPromise();
    }

    exportNonComplianceReport(params: any): Promise<any> {
        const options = createRequestOption(params);
        return this.http
            .get(SERVER_API_URL + 'api/reports/non-compliance/export', {
                responseType: 'arraybuffer',
                params: options
            })
            .toPromise();
    }

    mailNonComplianceReport(params: any): Promise<any> {
        const options = createRequestOption(params);
        return this.http
            .get(SERVER_API_URL + 'api/reports/non-compliance/mail', {
                params: options
            })
            .toPromise();
    }

    getOutstandingCallInReport(params: any): Promise<any> {
        const options = createRequestOption(params);
        return this.http
            .get(SERVER_API_URL + 'api/reports/outstanding-call-ins', {
                params: options
            })
            .toPromise();
    }

    exportOutstandingCallInReport(params: any): Promise<any> {
        const options = createRequestOption(params);
        return this.http
            .get(SERVER_API_URL + 'api/reports/outstanding-call-ins/export', {
                responseType: 'arraybuffer',
                params: options
            })
            .toPromise();
    }

    getUninvoicedWorkReport(params: any): Promise<any> {
        const options = createRequestOption(params);
        return this.http.get(SERVER_API_URL + 'api/reports/uninvoiced-work', {params: options}).toPromise();
    }

    exportUninvoicedWorkReport(params: any): Promise<any> {
        const options = createRequestOption(params);
        return this.http
            .get(SERVER_API_URL + 'api/reports/uninvoiced-work/export', {
                responseType: 'arraybuffer',
                params: options
            })
            .toPromise();
    }

    getClausedCallInReport(params: any): Promise<any> {
        const options = createRequestOption(params);
        return this.http.get(SERVER_API_URL + 'api/reports/claused-call-in', {params: options}).toPromise();
    }

    exportClausedCallInReport(params: any): Promise<any> {
        const options = createRequestOption(params);
        return this.http
            .get(SERVER_API_URL + 'api/reports/claused-call-in/export', {
                responseType: 'arraybuffer',
                params: options
            })
            .toPromise();
    }
}
