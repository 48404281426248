import {Component, OnDestroy, OnInit, AfterViewInit, ElementRef, ViewChild, HostListener} from '@angular/core';
import {
    faArrowCircleRight,
    faEye,
    faEyeSlash,
    faFile,
    faFileUpload,
    faHeadphones,
    faHistory,
    faKeyboard,
    faPen,
    faPlus,
    faRobot,
    faSyncAlt,
    faTrash
} from '@fortawesome/free-solid-svg-icons';
import {SuggestionService} from 'app/blocks/service/api/suggestion.service';
import {AIInputService, ScreenType} from 'app/blocks/service/ai-input.service';
import {Subject} from 'rxjs';
import {RoleService} from 'app/blocks/service/api/role.service';
import {DialogService} from 'app/common/dialog/dialog-service';
import {LogService} from 'app/blocks/service/log.service';
import {saveAs} from 'file-saver';
import {FileConversionService} from 'app/blocks/service/file-conversion.service';
import {SatPopover} from '@ncstate/sat-popover';
import {AIResource, querySearchOptions} from 'app/constants/ai';
import {Resource} from 'app/constants/resource';
import {convertToTitleCase} from 'app/blocks/util/conversion-util';

@Component({
    selector: 'ai-input',
    styleUrls: ['ai-input.component.scss'],
    template: `
        <span *ngIf="roleEnabled">
            <!-- Previous icons and controls remain the same -->
            <span *ngIf="!this.loading" class="mpos-form-row m-8">
                <span style="display: flex;">
                    <fa-icon [satPopoverAnchor]="textView" style="font-size: 22px;" [icon]="faRobot" (click)="textView.toggle()" class="ml-12">
                    </fa-icon>
                    <fa-icon [spin]="this.isRecording" style="font-size: 24px;" [icon]="faHeadphones" (click)="toggleRecording()" class="ml-12">
                    </fa-icon>
                </span>
            </span>
            <span *ngIf="this.loading" class="m-8">
                {{ loadingText }}
                <fa-icon [icon]="faUploading" [spin]="true"></fa-icon>
            </span>
        </span>

        <sat-popover horizontalAlign="end" verticalAlign="below" #textView hasBackdrop>
            <div class="bordered-popover">
                <div class="popover-title" style="margin: 5px">
                    <div class="container">
                        <span class="left"><b class="title">ChatMagic</b></span>
                        <span class="right">
                            <input
                                style="display: none"
                                type="file"
                                id="file"
                                size="60"
                                (change)="onFileSelected($event)"
                                accept=".*"
                                #fileInputElement />
                            <span *ngIf="!this.isRecording && !this.uploadedFile" class="file-upload">
                                <fa-icon style="font-size: 16px;" [icon]="faPlus" (click)="fileInputElement.click()" class="ml-12"></fa-icon>
                            </span>
                            <span *ngIf="this.uploadedFile" class="file-upload" (click)="clearFile($event)">
                                <fa-icon style="font-size: 16px;" [icon]="faFileUpload" class="ml-12"></fa-icon>
                            </span>
                            <fa-icon
                                [satPopoverAnchor]="history"
                                style="font-size: 16px;"
                                class="ml-12"
                                [icon]="faHistory"
                                (click)="history.toggle()"></fa-icon>
                        </span>
                    </div>
                </div>
                <div>
                    <div class="input-container">
                        <textarea #autoInput class="editable-input" [(ngModel)]="inputText" (input)="onInput($event)"></textarea>
                    </div>
                </div>

                <div class="dropdown">
                    <ng-container>
                        <nz-tabset>
                            <nz-tab [nzTitle]="actionsTabTitle">
                                <ng-template #actionsTabTitle>
                                    <span style="padding: 10px; font-weight: bold; color: black;">Actions</span>
                                </ng-template>
                                <div class="button-container" *ngIf="availableButtons.length > 0">
                                    <button *ngFor="let button of availableButtons" (click)="button.action()">
                                        {{ button.name }}
                                    </button>
                                </div>
                                <ng-container *ngIf="!searchLoading">
                                    <ul>
                                        <li *ngFor="let option of filteredOptions; let i = index" (click)="onOptionSelected(option)">
                                            <fa-icon
                                                style="font-size: 12px;"
                                                [class.selected]="i === selectedOptionIndex"
                                                [icon]="faArrowCircleRight"
                                                class="mr-12"></fa-icon>
                                            {{ option }}
                                        </li>
                                    </ul>
                                </ng-container>
                                <ul *ngIf="searchLoading">
                                    <li class="shimmer" *ngFor="let _ of [1, 2, 3, 4, 5]">
                                        <div style="height: 20px; width: 100%;"></div>
                                    </li>
                                </ul>
                                <nz-empty style="margin-top:100px" *ngIf="!searchLoading && filteredOptions.length === 0"></nz-empty>
                            </nz-tab>
                            <nz-tab [nzTitle]="reportTabTitle" *ngIf="rows.length > 0">
                                <ng-template #reportTabTitle>
                                    <span style="padding: 10px; font-weight: bold; color: black;">Report</span>
                                </ng-template>
                                <nz-empty style="margin-top:100px" *ngIf="rows.length == 0"></nz-empty>
                                <table style="overflow-x:auto;width:100%;display:block">
                                    <thead>
                                        <tr>
                                            <th style="min-width: 150px; padding: 10px;" *ngFor="let key of headers; let i = index">
                                                {{ getName(key) }}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let row of rows">
                                            <td style="min-width: 150px; padding: 10px;" *ngFor="let key of headers; let i = index">
                                                {{ row[key] }}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </nz-tab>
                            <nz-tab [nzTitle]="drawTabTitle" *ngIf="customUI != null">
                                <ng-template #drawTabTitle>
                                    <span style="padding: 10px; font-weight: bold; color: black;">Draw</span>
                                </ng-template>
                                <iframe
                                    *ngIf="customUI != null"
                                    #customui
                                    style="min-width:480px; max-width: 480px;margin:10px; border: none;min-height: 300px;max-height: 300px">
                                </iframe>
                            </nz-tab>
                        </nz-tabset>
                    </ng-container>
                </div>
            </div>
        </sat-popover>

        <sat-popover horizontalAlign="end" verticalAlign="below" #history hasBackdrop>
            <div class="bordered-popover">
                <div class="popover-title" style="margin: 5px">
                    <div class="container">
                        <span class="left"><b class="title">History</b></span>
                    </div>
                </div>
                <div class="dropdown">
                    <ul>
                        <li *ngFor="let prompt of promptHistory">
                            <span class="container" (click)="addToInput(prompt)">
                                <span class="left"
                                    ><fa-icon style="font-size: 12px;" [icon]="faHistory" class="mr-12"></fa-icon> {{ prompt.content }}</span
                                >
                                <span class="right">
                                    <fa-icon style="font-size: 12px;" [icon]="faTrash" class="ml-12" (click)="deletePrompt(prompt.id)"></fa-icon>
                                </span>
                            </span>
                        </li>
                    </ul>
                </div>
            </div>
        </sat-popover>
    `
})
export class AIInputComponent implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild('autoInput') autoInput: ElementRef;
    @ViewChild('customui') customui: ElementRef;
    @ViewChild('textView') textView: SatPopover;
    @ViewChild('history') history: SatPopover;
    @ViewChild('fileInputElement') fileInputElement!: ElementRef;
    private _unsubscribeAll = new Subject<void>();
    private mediaRecorder: MediaRecorder | null = null;
    private audioContext: AudioContext | null = null;
    private analyser: AnalyserNode | null = null;
    private recognition: any = null;
    promptHistory = [];
    private silenceTimeout: any;
    availableButtons = [];
    isRecording = false;
    loading = false;
    public loadingText = 'Deciding Command to execute...';

    faUploading = faSyncAlt;
    faKeyboard = faKeyboard;
    faEye = faEye;
    faEyeSlash = faEyeSlash;
    faFileUpload = faFileUpload;
    faRobot = faRobot;
    faArrowCircleRight = faArrowCircleRight;
    faHeadphones = faHeadphones;
    faHistory = faHistory;
    faTrash = faTrash;
    faPlus = faPlus;
    roleEnabled = true;
    uploadedFile: any;
    filteredOptions = [];
    prevValue = '';
    postValue = '';
    searchCode = '';
    timeToProcess = Date.now();
    keyAndClickCount = 0;
    lengthCount = 0;
    searchLoading = false;
    headers = [];
    rows = [];
    customUI = null;
    customOptions = null;
    inputText = '';
    command = '';
    insertingKeys = [
        'a',
        'b',
        'c',
        'd',
        'e',
        'f',
        'g',
        'h',
        'i',
        'j',
        'k',
        'l',
        'm',
        'n',
        'o',
        'p',
        'q',
        'r',
        's',
        't',
        'u',
        'v',
        'w',
        'x',
        'y',
        'z',
        'A',
        'B',
        'C',
        'D',
        'E',
        'F',
        'G',
        'H',
        'I',
        'J',
        'K',
        'L',
        'M',
        'N',
        'O',
        'P',
        'Q',
        'R',
        'S',
        'T',
        'U',
        'V',
        'W',
        'X',
        'Y',
        'Z',
        '0',
        '1',
        '2',
        '3',
        '4',
        '5',
        '6',
        '7',
        '8',
        '9',
        ' ',
        '!',
        '@',
        '#',
        '$',
        '%',
        '^',
        '&',
        '*',
        '(',
        ')',
        '-',
        '_',
        '=',
        '+',
        '[',
        ']',
        '{',
        '}',
        '\\',
        '|',
        ';',
        ':',
        "'",
        '"',
        ',',
        '.',
        '/',
        '<',
        '>',
        '?',
        '`',
        '~',
        'Delete',
        'Backspace'
    ];
    selectedOptionIndex: number = 0;
    okMagic = false;

    constructor(
        private suggestionService: SuggestionService,
        public aIInputService: AIInputService,
        private roleService: RoleService,
        protected _dialogService: DialogService,
        private logService: LogService,
        private fileConversionService: FileConversionService
    ) {
        if ('webkitSpeechRecognition' in window) {
            this.recognition = new (window as any).webkitSpeechRecognition();
        } else {
            console.error('Speech recognition is not supported in this browser.');
            this.recognition = null;
        }

        this.roleService.roleSettings.subscribe((roleSettings) => {
            if (roleSettings['ai-feature'] != null) {
                this.roleEnabled = roleSettings['ai-feature'];
                if (this.roleEnabled) {
                    this.loadPrompts();
                }
            }
        });

        this.aIInputService.loadingText.subscribe((loadingText) => {
            this.loadingText = loadingText;
        });

        this.aIInputService.tableData.subscribe((tableData) => {
            this.setTableData(tableData);
            setTimeout(() => {
                if (this.rows?.length > 0) {
                    this.textView.open();
                }
            }, 1000);
        });
        this.aIInputService.dialogueData.subscribe((dialogueData) => {
            if (dialogueData) {
                this._dialogService.showMessage({
                    message: dialogueData,
                    title: 'AI Response',
                    confirmText: `OK`
                });
            }
        });
        this.aIInputService.lastExecutedCommand.subscribe((lastExecutedCommand) => {
            const lastCommandResponse = this.aIInputService.context[lastExecutedCommand];
            this.setButtons(lastExecutedCommand, lastCommandResponse);
        });
    }

    clearFile($event) {
        this.uploadedFile = null;
        this.fileInputElement.nativeElement.value = '';
    }

    setButtons(lastExecutedCommand, lastCommandResponse) {
        const resource = this.aIInputService.currentResource.value;
        switch (lastExecutedCommand) {
            case 'query':
                setTimeout(() => {
                    this.textView.open();
                }, 1000);
                if (this.rows?.length > 0) {
                    this.availableButtons = [
                        {
                            name: 'Download',
                            action: () => {
                                this.filteredOptions = ['Download a CSV', 'Download a Doc', 'Download a Sheet', 'Download a Slide'];
                                this.customOptions = 'Download';
                            }
                        },
                        {
                            name: 'Email',
                            action: () => {
                                this.filteredOptions = ['Email a CSV', 'Email a Doc', 'Email a Sheet', 'Email a Slide'];
                                this.customOptions = 'Email';
                            }
                        }
                    ];
                    if (this.rows[0]['id'] && AIResource[resource]) {
                        if (AIResource[resource].deSelectEntities) {
                            this.availableButtons.push({
                                name: 'Deselect',
                                action: () => {
                                    this.aIInputService.entityCommands['deSelectEntities'].action(lastCommandResponse, resource);
                                }
                            });
                        }
                        if (AIResource[resource].selectEntities) {
                            this.availableButtons.push({
                                name: 'Select',
                                action: () => {
                                    this.aIInputService.entityCommands['selectEntities'].action(lastCommandResponse, resource);
                                }
                            });
                        }
                    }
                    // this.availableButtons.push({
                    //     name: 'Visualize',
                    //     action: () => {
                    //         this.filteredOptions = this.groupOptions(['Create a Bubble Chart', 'Create a Bar Chart', 'Create a Line Chart']);
                    //         this.customOptions = 'Visualize';
                    //     }
                    // });
                }
                break;
            default: {
                this.availableButtons = [];
                this.customOptions = null;
            }
        }
    }

    ngOnInit(): void {
        if (this.okMagic) {
            this.webCommandInit();
        }
    }

    ngAfterViewInit(): void {}

    setTableData(info: any) {
        if (!info || info.length === 0) {
            return;
        }

        for (const data of info) {
            for (const key in data) {
                if (data[key] instanceof String || typeof data[key] == 'string') {
                    data[key] = data[key].replace(/null/g, 'n/a');
                }
            }
        }

        if (info && info.length !== 0) {
            const firstRow = info[0];
            const headers = Object.keys(firstRow)
                .filter((key) => !key.endsWith('id'))
                .sort();

            this.headers = headers;

            // Reorder each row to match the headers order
            this.rows = info.map((row) => {
                const reorderedRow = {};
                headers.forEach((header) => {
                    reorderedRow[header] = row[header];
                });
                return reorderedRow;
            });
        }
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
        this.audioContext?.close();
        this.recognition?.stop();
    }

    @HostListener('document:keydown', ['$event'])
    handleKeyDown(event: KeyboardEvent) {
        if (!this.textView._open) {
            return;
        }

        const cursorPosition = this.autoInput.nativeElement.selectionStart;
        const selectionEnd = this.autoInput.nativeElement.selectionEnd;
        const value = this.inputText;
        const selectionRange = value.substring(cursorPosition, selectionEnd);

        if (event.key === 'ArrowRight') {
            if (event.ctrlKey) {
                event.preventDefault();
                // Move cursor to the next comma or closing parenthesis
                let nextPosition = cursorPosition;
                if (value[cursorPosition] === ')' || value[cursorPosition] === ',') {
                    nextPosition++;
                }
                while (nextPosition < value.length) {
                    if (value[nextPosition] === ',' || value[nextPosition] === ')') {
                        break;
                    }
                    nextPosition++;
                }
                this.autoInput.nativeElement.selectionStart = nextPosition;
                this.autoInput.nativeElement.selectionEnd = nextPosition;
            }
            this.onInput(event);
            return;
        }

        if (event.key === 'ArrowLeft') {
            if (event.ctrlKey) {
                event.preventDefault();
                let prevPosition = cursorPosition - 1;
                while (prevPosition >= 0) {
                    if (value[prevPosition] === ',' || value[prevPosition] === ')') {
                        break;
                    }
                    prevPosition--;
                }
                this.autoInput.nativeElement.selectionStart = prevPosition;
                this.autoInput.nativeElement.selectionEnd = prevPosition;
            }
            this.onInput(event);
            return;
        }

        if (event.key === 'ArrowDown' && event.ctrlKey && this.filteredOptions.length > 0) {
            event.preventDefault();
            this.selectedOptionIndex = (this.selectedOptionIndex + 1) % this.filteredOptions.length;
            return;
        }

        if (event.key === 'ArrowUp' && event.ctrlKey && this.filteredOptions.length > 0) {
            event.preventDefault();
            this.selectedOptionIndex = (this.selectedOptionIndex - 1 + this.filteredOptions.length) % this.filteredOptions.length;
            return;
        }

        if (event.key === 'Tab') {
            if (this.filteredOptions.length > 0) {
                event.preventDefault();
                this.onOptionSelected(this.filteredOptions[this.selectedOptionIndex]);
            }
            return;
        }

        if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault();
            this.processText(event);
            return;
        }

        if (this.isWithinBrackets(cursorPosition)) {
            if (event.key === 'Delete') {
                if (value[cursorPosition] == '(' || value[cursorPosition] == ')' || value[cursorPosition] == ',' || value[cursorPosition] == ':') {
                    event.preventDefault();
                }
            }
            if (event.key === 'Backspace') {
                if (
                    value[cursorPosition - 1] == '(' ||
                    value[cursorPosition - 1] == ')' ||
                    value[cursorPosition - 1] == ',' ||
                    value[cursorPosition - 1] == ':'
                ) {
                    event.preventDefault();
                }
            }
            if (event.key === ':' || event.key == ',' || event.key == '(' || event.key == ')') {
                event.preventDefault();
            }
        }

        //Deletes entire function if backspace is pressed at the ending of the function
        if (event.key === 'Backspace') {
            if (value[cursorPosition - 1] == ')' && cursorPosition == selectionEnd) {
                event.preventDefault();
                // Iterate left to find the '('
                let leftIndex = cursorPosition - 1;
                while (leftIndex >= 0 && value[leftIndex] !== '(') {
                    leftIndex--;
                }

                if (leftIndex == -1) {
                    return;
                }

                let wordStart = leftIndex - 1;
                while (wordStart >= 0 && /\w/.test(value[wordStart])) {
                    wordStart--;
                }
                this.inputText = value.substring(0, wordStart + 1) + value.substring(cursorPosition);
                setTimeout(() => {
                    this.autoInput.nativeElement.selectionStart = wordStart + 1;
                    this.autoInput.nativeElement.selectionEnd = wordStart + 1;
                    this.onInput(event);
                }, 50);
            }
        }

        if (this.inputText.length == selectionRange.length) {
            return;
        }

        if (this.isFunctionName() && this.insertingKeys.includes(event.key)) {
            event.preventDefault();
        }

        if (
            (this.isWithinBrackets(cursorPosition) && !this.isWithinBrackets(selectionEnd)) ||
            (!this.isWithinBrackets(cursorPosition) && this.isWithinBrackets(selectionEnd)) ||
            this.isFunctionName()
        ) {
            if (this.insertingKeys.includes(event.key)) {
                event.preventDefault();
            }
        }

        this.keyAndClickCount++;
    }

    isFunctionName(): boolean {
        const cursorStart = this.autoInput.nativeElement.selectionStart;
        const cusorEnd = this.autoInput.nativeElement.selectionEnd;
        const value = this.inputText;

        const checkPosition = (position: number): boolean => {
            let nextPosition = position;
            let isFunctionName = false;
            while (nextPosition < value.length) {
                if (value[nextPosition] === ':' || value[nextPosition] === '(') {
                    isFunctionName = true;
                    break;
                }
                if (!/\w/.test(value[nextPosition])) {
                    // Check for any special character except word characters and spaces
                    break;
                }
                nextPosition++;
            }
            nextPosition++;
            while (nextPosition < value.length && value[nextPosition] !== ')') {
                if (value[nextPosition] === '(') {
                    isFunctionName = false;
                    break;
                }
                nextPosition++;
            }
            return isFunctionName;
        };

        return checkPosition(cursorStart) || checkPosition(cusorEnd);
    }

    isWithinBrackets(position: number) {
        const value = this.inputText;
        let leftIndex = position - 1;
        let rightIndex = position;

        // Iterate left to find the '('
        while (leftIndex >= 0 && value[leftIndex] !== '(') {
            if (value[leftIndex] === ')') {
                return false;
            }
            leftIndex--;
        }

        // Iterate right to find the ')'
        while (rightIndex < value.length && value[rightIndex] !== ')') {
            if (value[rightIndex] === '(') {
                return false;
            }
            rightIndex++;
        }

        return value[leftIndex] === '(' && value[rightIndex] === ')';
    }

    onInput($event) {
        const value = this.inputText;

        if (!value || value.trim() === '') {
            this.prevValue = '';
            this.postValue = '';
            this.rows = [];
            this.headers = [];
            this.customUI = null;
            this.availableButtons = [];
            this.command = null;
            this.aIInputService.aiSearchService.searchCache = {};
            this.aIInputService.searchParams = {};
            this.resetState(false);
            return;
        }

        const lastIndex = this.inputText.lastIndexOf('command(');
        if (lastIndex !== -1) {
            const endIndex = this.inputText.indexOf(')', lastIndex);
            if (endIndex !== -1) {
                const command = this.inputText.substring(lastIndex + 8, endIndex);
                this.command = command;
            } else {
                this.prevValue = 'command(';
                this.postValue = ') ';
                this.search('command', value);
                return;
            }
        } else {
            this.prevValue = 'command(';
            this.postValue = ') ';
            this.search('command', value);
            return;
        }

        if (this.searchResource()) {
            return;
        }

        if (!this.customOptions) {
            this.searchOption();
        }
    }

    searchOption() {
        const value = this.inputText;
        const element = this.autoInput.nativeElement;
        const cursorPosition = element.selectionStart;

        let leftIndex = cursorPosition - 1;
        let rightIndex = cursorPosition;

        while (leftIndex >= 0 && value[leftIndex] !== ' ') {
            leftIndex--;
        }

        while (rightIndex < value.length && value[rightIndex] !== ' ') {
            rightIndex++;
        }

        const searchValue = value.substring(leftIndex + 1, rightIndex);

        const prevValue = value.substring(0, leftIndex + 1);
        const postValue = value.substring(rightIndex + 1);

        this.prevValue = prevValue;
        this.postValue = postValue;

        this.search(null, searchValue, this.getSearchOptions());
    }

    searchResource() {
        const value = this.inputText;
        const element = this.autoInput.nativeElement;
        const cursorPosition = element.selectionStart;

        let leftIndex = cursorPosition - 1;
        let rightIndex = cursorPosition;

        // Iterate left to find the '('
        while (leftIndex >= 0 && value[leftIndex] !== '(') {
            if (value[leftIndex] === ')') {
                break;
            }
            leftIndex--;
        }

        // Iterate right to find the ')'
        while (rightIndex < value.length && value[rightIndex] !== ')') {
            if (value[rightIndex] === '(') {
                break;
            }
            rightIndex++;
        }

        if (value[leftIndex] === '(' && value[rightIndex] === ')') {
            let wordStart = leftIndex - 1;
            while (wordStart >= 0 && /\w/.test(value[wordStart])) {
                wordStart--;
            }

            const searchCode = value.substring(wordStart + 1, leftIndex);
            let parameter = value.substring(leftIndex + 1, rightIndex);
            const parameterLength = parameter.length;

            const parameters = [];
            let paramStart = 0;
            if (parameter.length === 0) {
                parameters.push({
                    value: '',
                    start: leftIndex + 1,
                    end: leftIndex + 1,
                    position: 1
                });
            } else {
                for (let i = 0; i < parameter.length; i++) {
                    if (parameter[i] == ',' || i == parameterLength - 1) {
                        parameters.push({
                            value: parameter.substring(paramStart, i == parameterLength - 1 ? i + 1 : i),
                            start: leftIndex + 1 + paramStart,
                            end: leftIndex + 1 + (i == parameterLength - 1 ? i + 1 : i),
                            position: parameters.length
                        });
                        paramStart = i + 1;
                    }
                }
            }

            let selectedParameter = null;
            for (let i = 0; i < parameters.length; i++) {
                if (parameters[i].start <= cursorPosition && parameters[i].end >= cursorPosition) {
                    selectedParameter = parameters[i];
                    break;
                }
            }

            if (selectedParameter) {
                this.prevValue = value.substring(0, selectedParameter.start);
                this.postValue = value.substring(selectedParameter.end);

                if (selectedParameter.value.includes(':')) {
                    const [key, val] = selectedParameter.value.split(':').map((part) => part.trim());
                    this.search(key, val);
                } else {
                    this.search(searchCode, selectedParameter.value);
                }

                if (parameters.length == 1) {
                    this.aIInputService.searchParams[searchCode] = selectedParameter.value;
                } else {
                    this.aIInputService.searchParams[searchCode + '_' + selectedParameter.position] = selectedParameter.value;
                }

                return true;
            }
        }
        return false;
    }

    search(code: string, value = '', searchOptions = []) {
        this.searchCode = code;
        this.searchLoading = true;
        const searchFunction = this.aIInputService.aiSearchService.getInputSearchFunction(code, searchOptions, this.aIInputService.searchParams);
        if (searchFunction) {
            this.searchLoading = true;
            searchFunction(value).then((data) => {
                this.filteredOptions = data.length > 0 ? data : searchOptions;
                this.searchLoading = false;
                this.autoInput.nativeElement.focus();
            });
        } else {
            this.filteredOptions = [];
            this.searchLoading = false;
            this.autoInput.nativeElement.focus();
        }
    }

    onOptionSelected(event: any) {
        this.keyAndClickCount++;
        if (this.processCustomOptions(event)) {
            return;
        }
        this.aIInputService.aiSearchService.addHistory(event);
        this.inputText = `${this.prevValue}${event}${this.postValue}`;
        this.filteredOptions = [];
        this.selectedOptionIndex = 0;
        this.autoInput.nativeElement.focus();
        this.aIInputService.searchParams[this.searchCode] = event;
        setTimeout(() => {
            let nextPosition = this.prevValue.length;
            if (this.prevValue[this.prevValue.length - 1] == '(' && this.postValue[0] == ')') {
                this.onInput(event);
                return;
            }

            // Find the position to place the cursor
            while (this.inputText[nextPosition] !== ',' && this.inputText[nextPosition] !== ')' && nextPosition < this.inputText.length) {
                nextPosition++;
            }

            if (!event.includes('(') && !event.includes(')')) {
                // If the current position is a comma, move to the next parameter
                if (this.inputText[nextPosition] === ',') {
                    nextPosition++;
                    while (this.inputText[nextPosition] !== ',' && this.inputText[nextPosition] !== ')' && nextPosition < this.inputText.length) {
                        nextPosition++;
                    }
                }
            }

            // Set the cursor position
            this.autoInput.nativeElement.selectionStart = nextPosition;
            this.autoInput.nativeElement.selectionEnd = nextPosition;
            this.onInput(event);
        }, 50);
    }

    private processCustomOptions(option: any) {
        switch (this.customOptions) {
            case 'Visualize':
                this.loading = true;
                this.aIInputService.loadingText.next('Drawing...');
                this.customUI = '';
                this.suggestionService.geminiUI(option, this.rows).then((response) => {
                    if (response?.html !== '' && response?.html !== null) {
                        this.customUI = response?.html;
                        this.customui.nativeElement.srcdoc = this.customUI;
                    } else {
                        this.customUI = null;
                    }
                    this.resetState(false);
                    setTimeout(() => {
                        this.textView.open();
                    }, 1000);
                });
                return true;
            case 'Download':
                switch (option) {
                    case 'Download a CSV':
                        this.downloadCSV();
                        break;
                    case 'Download a Doc':
                        this.downloadDOC();
                        break;
                    case 'Download a Sheet':
                        this.downloadSheet();
                        break;
                    case 'Download a Slide':
                        this.downloadSlide();
                        break;
                }
                return true;
            case 'Email':
                switch (option) {
                    case 'Email a CSV':
                        this.downloadCSV();
                        break;
                    case 'Email a Doc':
                        this.downloadDOC(true);
                        break;
                    case 'Email a Sheet':
                        this.downloadSheet(true);
                        break;
                    case 'Email a Slide':
                        this.downloadSlide(true);
                        break;
                }
                return true;
            default:
                return false;
        }
    }

    // Update the processText method to work with the new contenteditable div
    processText(event: any) {
        this.loading = true;
        this.recognition?.stop();

        if (this.inputText === '') {
            this.resetState(true);
            return;
        }

        this.suggestionService.getCommandText(this.inputText, this.aIInputService.getCommands()).then((response) => {
            this.proccessCommandResponse(response, this.inputText);
        });
    }

    resizeTextarea() {
        const textarea = this.autoInput.nativeElement;
        textarea.style.height = 'auto';
        textarea.style.height = textarea.scrollHeight + 'px';
    }

    webCommandInit() {
        if (this.recognition == null) {
            return;
        }

        this.recognition.interimResults = true;
        this.recognition.continuous = true;

        this.recognition.addEventListener('result', this.onWebCommand.bind(this));
        this.recognition.start();
    }

    onWebCommand(event) {
        const arr = Array.from(event.results);
        if (arr.length > 0) {
            const results = arr[arr.length - 1][0].transcript.split(' ').map((word) => this.sanitizeInput(word));
            this.OkMagic(results);
        }
    }

    toggleRecording() {
        if (this.isRecording) {
            this.stopRecording();
        } else {
            this.startRecording();
        }
    }

    OkMagic(input: any[]): boolean {
        if (input.length < 2) {
            return;
        }
        if (!input[0].includes('ok') || !input[1].includes('magic')) {
            return;
        }

        if (!this.isRecording) {
            this.toggleRecording();
        }
    }

    startRecording() {
        this.isRecording = true;
        if (this.okMagic) {
            this.recognition?.stop();
        }
        navigator.mediaDevices
            .getUserMedia({audio: true})
            .then((stream) => {
                this.mediaRecorder = new MediaRecorder(stream);
                this.mediaRecorder.addEventListener('dataavailable', this.audioEvent.bind(this));
                this.mediaRecorder.start();

                this.audioContext = new AudioContext();
                const source = this.audioContext.createMediaStreamSource(stream);
                this.analyser = this.audioContext.createAnalyser();
                source.connect(this.analyser);
                this.analyser.fftSize = 2048;

                this.detectSilence();
            })
            .catch((error) => console.error('Error accessing the microphone', error));
    }

    stopRecording() {
        this.isRecording = false;
        this.mediaRecorder?.stop();
        clearTimeout(this.silenceTimeout);
    }

    detectSilence() {
        if (!this.analyser) return;

        const bufferLength = this.analyser.fftSize;
        const dataArray = new Uint8Array(bufferLength);
        let silentCount = 0;

        const checkSilence = () => {
            this.analyser?.getByteTimeDomainData(dataArray);

            const silentFrames = dataArray.filter((value) => value === 128).length;
            const isSilent = silentFrames / bufferLength >= 0.45;

            if (isSilent) {
                silentCount++;
                if (silentCount >= 5) {
                    if (this.isRecording) {
                        this.stopRecording();
                    }
                    return;
                }
            } else {
                silentCount = 0;
            }

            setTimeout(checkSilence, 1000); // Check every 1 seconds
        };

        // Start silence detection after an initial 10-second delay
        setTimeout(checkSilence, 5000);
    }

    audioEvent(event) {
        this.loading = true;
        const audioFile = new File([event.data], 'data.audio', {type: event.data.type});
        this.suggestionService.getCommand(audioFile, this.aIInputService.getCommands()).then((response) => {
            this.proccessCommandResponse(response, audioFile);
        });
    }

    onFileSelected(event: any) {
        this.uploadedFile = event.target.files[0];
        if (this.aIInputService.screenType.value == ScreenType.CREATE) {
            this.loading = true;
            this.proccessCommandResponse(
                {commands: ['createEntity'], audioTranscript: null, suggestion: '', identifyPromptResource: this.aIInputService.resourceName},
                'Create'
            );
        }
    }

    proccessCommandResponse(response, prompt: any): any {
        let preInput = '';
        if (typeof prompt === 'string') {
            preInput = prompt;
        } else {
            preInput = response?.audioTranscript;
        }

        if (!preInput || preInput === '') {
            this.resetState(true);
            return;
        }

        if (response?.commands.join('') === '' && response?.suggestion !== '') {
            this.aIInputService.dialogueData.next(response?.suggestion);
            this.resetState(true);
            return true;
        }

        this.aIInputService.processCommands(preInput, this.uploadedFile, response?.commands, response?.identifyPromptResource).finally(() => {
            const timeTaken = Date.now() - this.timeToProcess;
            this.logService.logDirect('AI Execution', {
                timeTaken: timeTaken,
                input: preInput,
                keyAndClickCount: this.keyAndClickCount
            });
            this.resetState(true);
            this.loadPrompts();
        });
    }

    resetState(startRecognition = false) {
        this.timeToProcess = Date.now();
        this.keyAndClickCount = 0;
        this.lengthCount = 0;
        this.loading = false;
        if (startRecognition && this.okMagic) {
            this.recognition?.start();
        }
        this.loadingText = 'Deciding Command to execute';
        this.filteredOptions = [];
    }

    sanitizeInput(input: string): string {
        return input
            ?.toLowerCase()
            .replace(/[^a-zA-Z0-9 ]/g, '')
            .trim();
    }

    downloadFile(file: Blob, fileName: string) {
        saveAs(file, fileName);
    }

    private downloadCSV(sendToBackend = false) {
        if (this.rows?.length === 0) {
            return;
        }
        const blob = this.fileConversionService.convertToCSV(this.rows);
        if (sendToBackend) {
            this.emailFile(blob, 'data.csv');
        } else {
            this.downloadFile(blob, 'data.csv');
        }
    }

    private downloadDOC(sendToBackend = false) {
        if (this.rows?.length === 0) {
            return;
        }
        this.fileConversionService.convertToDOC(this.rows).then((blob) => {
            if (sendToBackend) {
                this.emailFile(blob, 'document.docx');
            } else {
                this.downloadFile(blob, 'document.docx');
            }
        });
    }

    private downloadSheet(sendToBackend = false) {
        if (this.rows?.length === 0) {
            return;
        }
        const blob = this.fileConversionService.convertToSheet(this.rows);
        if (sendToBackend) {
            this.emailFile(blob, 'spreadsheet.xlsx');
        } else {
            this.downloadFile(blob, 'spreadsheet.xlsx');
        }
    }

    downloadSlide(sendToBackend = false) {
        if (this.rows?.length === 0) {
            return;
        }
        this.fileConversionService.convertToSlide(this.rows).then((blob) => {
            if (sendToBackend) {
                this.emailFile(blob, 'presentation.pptx');
            } else {
                this.downloadFile(blob, 'presentation.pptx');
            }
        });
    }

    private emailFile(file: Blob, fileName: string) {
        this.loading = true;
        this.loadingText = 'Sending Email...';
        this.suggestionService.sendEmail(file, fileName).then(() => {
            this.resetState(false);
        });
    }

    addToInput(prompt: any): void {
        // Assuming you have a method to update the input field
        this.inputText = prompt.content;
        this.history.close();
    }

    deletePrompt(promptId: any): void {
        this.suggestionService.deletePrompt(promptId).then(() => {
            this.loadPrompts();
            this.history.close();
        });
    }

    loadPrompts(): void {
        this.suggestionService.getPrompts().then((prompts) => {
            this.promptHistory = prompts;
        });
    }

    getSearchOptions(): string[] {
        const value = this.inputText;
        const position = this.autoInput.nativeElement.selectionStart;
        if (value[position - 1] == ')') {
            return;
        }
        const options = ['command()'];
        let searchOptions = this.aIInputService.aiSearchService.searchOptions[this.command];
        if (this.aIInputService.aiSearchService.screenSearchOptions[this.command]) {
            searchOptions = this.aIInputService.aiSearchService.screenSearchOptions[this.command];
        }
        if (this.command == 'Query') {
            searchOptions = querySearchOptions;
        }
        if (searchOptions) {
            for (const key of Object.keys(searchOptions)) {
                const option = searchOptions[key];
                if (!option.repeatable && this.inputText.includes(key + '(')) {
                    continue;
                }
                options.push(option.function + ' ');
            }
        }
        return options;
    }

    getName(key: string): string {
        return convertToTitleCase(key);
    }
}
