import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    OnDestroy,
    OnInit,
    ViewChild,
    AfterViewInit,
    Input,
    HostListener,
    Output,
    EventEmitter
} from '@angular/core';
import {fromEvent, Subject, BehaviorSubject} from 'rxjs';
import {debounceTime, distinctUntilChanged, takeUntil} from 'rxjs/operators';

@Component({
    selector: 'di-search-bar',
    template: `
        <div fxLayout="row" fxLayoutAlign="start center" *ngIf="show">
            <nz-input-group fxFlex [nzPrefix]="prefixIconSearch" [nzSuffix]="suffixClear">
                <input
                    id="{{ 'di-search-bar-' + idSuffix }}"
                    style="color: black;"
                    type="text"
                    nz-input
                    #filter
                    [disabled]="disabled"
                    placeholder="{{ 'common.search' | translate }}" />
            </nz-input-group>
            <ng-template #prefixIconSearch>
                <a (click)="handleEnterKey()">
                    <i nz-icon nzType="search"></i>
                </a>
            </ng-template>
            <ng-template #suffixClear>
                <a (click)="setSearchTerm('')"><i style="color: #2cabb8 !important" *ngIf="searchTerm$ | async" nz-icon nzType="close-circle"></i></a>
            </ng-template>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DiSearchBarComponent implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild('filter', {static: false})
    private _filter: ElementRef;

    @Input() disabled = false;
    @Input() show = true;
    @Input() idSuffix = '';
    @Input() autoSearchEnable = false;

    @Output() onSearchChanged = new EventEmitter<any>();

    private _searchTermSubject = new BehaviorSubject('');
    public readonly searchTerm$ = this._searchTermSubject.asObservable();

    private _unsubscribeAll = new Subject<void>();

    constructor() {}

    ngOnInit(): void {}

    ngAfterViewInit(): void {
        if (this.autoSearchEnable) {
            fromEvent(this._filter.nativeElement, 'input')
                .pipe(debounceTime(500), distinctUntilChanged(), takeUntil(this._unsubscribeAll))
                .subscribe((event: any) => {
                    const term = (event?.target as HTMLInputElement).value;
                    this._searchTermSubject.next(term);
                    this.onSearchChanged.emit(term);
                });
        }
    }

    @HostListener('document:keydown.enter', ['$event'])
    handleEnterKey(event?: KeyboardEvent): void {
        if (!this.autoSearchEnable && this._filter) {
            const term = this._filter.nativeElement.value;
            this._searchTermSubject.next(term);
            this.onSearchChanged.emit(term);
        }
    }

    getCurrentSearchTerm(): string {
        return this._searchTermSubject.getValue();
    }

    setSearchTerm = (term: string): void => {
        if (term !== null) {
            this._filter.nativeElement.value = term;
            this._searchTermSubject.next(term);
            this.onSearchChanged.emit(term);
        }
    };

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
}
