import {LegLocationDateTimeChange} from 'app/blocks/model/leg-location-date-time-changed.model';
import {LoadBuilderModel} from 'app/blocks/model/load-builder-model';
import {SERVER_API_URL} from 'app/app.constants';
import {HttpClient, HttpParams, HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {ILeg, Leg} from 'app/blocks/model/leg.model';
import {ILegResource} from 'app/blocks/model/leg-resource.model';
import {CallIn} from 'app/blocks/model/call-in.model';
import EntityCrudService from 'app/blocks/service/api/entity-service';
import {Resource} from 'app/constants/resource';
import {toIsoLocalDateTimeString} from 'app/common/utils/date-utils';
import {Observable} from 'rxjs';
import {createRequestOption, createRequestOption2} from 'app/blocks/util/request-util';

@Injectable({
    providedIn: 'root'
})
export class LegService extends EntityCrudService<ILeg> {
    constructor(http: HttpClient) {
        super(http, Resource.LEGS, (obj?) => new Leg(obj));
    }

    public getAllLegs = (): Promise<any[]> => {
        return this.http.get<ILeg[]>(`${SERVER_API_URL}api/legs`).toPromise();
    };

    public updateLegFromPlanningBoard = (leg: any): Promise<any[]> => {
        return this.http.put<ILeg[]>(`${SERVER_API_URL}api/planning-board-leg`, leg).toPromise();
    };

    bulkUpdate = (legs): Promise<any> => {
        const requestParams = new HttpParams().set('legs', legs);
        return this.http
            .put<ILeg[]>(SERVER_API_URL + 'api/legs/bulk-update', legs, {
                observe: 'response'
            })
            .toPromise();
    };

    /**
     *This is going to be depricated, should only be called from the planning Board..
     *
     * @memberof LegService
     */
    public updateLegResourcesFromPlanningBoard = (legResources: any): Promise<any[]> => {
        return this.http.put<ILegResource[]>(`${SERVER_API_URL}api/update-leg-resource`, legResources).toPromise();
    };

    public resourceLegs = (legResources: any[]): Observable<HttpResponse<any[]>> => {
        return this.http.post<ILeg[]>(`${SERVER_API_URL}api/legs/resource`, legResources, {observe: 'response'});
    };

    public unResourceLegsForTrafficSheets = (ids: number[]): Observable<HttpResponse<any[]>> => {
        return this.http.post<any[]>(`${SERVER_API_URL}api/legs/unresource`, {legIds: ids}, {observe: 'response'});
    };

    public unSubContractLegsForTrafficSheets = (ids: number[]): Observable<HttpResponse<any[]>> => {
        return this.http.post<any[]>(`${SERVER_API_URL}api/legs/unsubcontract`, {legIds: ids}, {observe: 'response'});
    };

    public subContractLegsforTrafficSheets = (legResources: any[]): Observable<HttpResponse<any[]>> => {
        return this.http.post<any[]>(`${SERVER_API_URL}api/legs/subcontract`, legResources, {observe: 'response'});
    };

    public unResourceLegs = (ids: number[]): Promise<any[]> => {
        return this.http.post<any[]>(`${SERVER_API_URL}api/legs/unresource`, {legIds: ids}).toPromise();
    };

    public resource = (legResources: any[]): Promise<any[]> => {
        return this.http.post<any[]>(`${SERVER_API_URL}api/legs/resource`, legResources).toPromise();
    };

    public communicateLegs = (ids: number[]): Promise<any[]> => {
        return this.http.post<any[]>(`${SERVER_API_URL}api/legs/communicate`, {legIds: ids}).toPromise();
    };

    public unCommunicateLegs = (ids: number[]): Promise<any[]> => {
        return this.http
            .post<any[]>(`${SERVER_API_URL}api/legs/uncommunicate`, {
                legIds: ids
            })
            .toPromise();
    };

    public cancelOrderInLeg = (model: LegLocationDateTimeChange): Promise<any[]> => {
        return this.http.post<any[]>(`${SERVER_API_URL}api/legs/cancel-order-in-leg`, model).toPromise();
    };

    public removeOrderInLeg = (orderIds: number[], runId: number): Promise<any[]> => {
        const request = {
            runId: runId,
            orders: orderIds
        };
        return this.http.post<any[]>(`${SERVER_API_URL}api/legs/remove-order`, request).toPromise();
    };

    public collectionAttemptLater = (model: LegLocationDateTimeChange): Promise<any[]> => {
        return this.http.post<any[]>(`${SERVER_API_URL}api/legs/collection-attempt-later`, model).toPromise();
    };

    public deliveryAttemptLater = (model: LegLocationDateTimeChange): Promise<any[]> => {
        return this.http.post<any[]>(`${SERVER_API_URL}api/legs/delivery-attempt-later`, model).toPromise();
    };

    public partialDelivery = (model: LegLocationDateTimeChange): Promise<any[]> => {
        return this.http.post<any[]>(`${SERVER_API_URL}api/legs/partial-delivery`, model).toPromise();
    };

    public createOrderFromLoad = (list: LoadBuilderModel[]): Promise<any[]> => {
        return this.http.post<any[]>(`${SERVER_API_URL}api/legs/create-orders-from-load`, list).toPromise();
    };

    public subContractLegs = (legResources: any[]): Promise<any[]> => {
        return this.http.post<any[]>(`${SERVER_API_URL}api/legs/subcontract`, legResources).toPromise();
    };

    public changeSubContractRate = (ids: any[], rate: any): Promise<any[]> => {
        return this.http
            .post<any[]>(`${SERVER_API_URL}api/legs/update-subcontract-rate`, {
                legIds: ids,
                sendSubContractCancellationEmail: false,
                rate: rate
            })
            .toPromise();
    };

    /**
     * Take the Id of the leg to be communicated
     * @param id
     * @returns
     */
    public updateCommunicateLeg = (ids: number[]): Promise<any[]> => {
        return this.http.post<any>(`${SERVER_API_URL}api/legs/communicate`, {legIds: ids}).toPromise();
    };

    /**
     * Take the Id of the leg to be communicated
     * @param id
     * @returns
     */
    public updateUncommunicateLeg = (ids: number[]): Promise<any[]> => {
        return this.http.post<any>(`${SERVER_API_URL}api/legs/uncommunicate`, {legIds: ids}).toPromise();
    };

    /**
     * Post call-in request - the CallIn contents include the leg id and items collected / delivered
     * @param id
     * @returns
     */
    public updateCallInLeg = (callIn: CallIn): Promise<any> => {
        return this.http
            .post<any>(`${SERVER_API_URL}api/legs/call-in`, {
                legId: callIn.legId,
                lateReasonId: callIn.lateReasonId,
                clausedDeliveryReasonId: callIn.clausedDeliveryReasonId,
                arrivalDateTime: callIn.arrivalDateTime instanceof Date ? toIsoLocalDateTimeString(callIn.arrivalDateTime) : callIn.arrivalDateTime,
                departureDateTime:
                    callIn.departureDateTime instanceof Date ? toIsoLocalDateTimeString(callIn.departureDateTime) : callIn.departureDateTime,
                gtCarCollectArray: callIn.gtCarCollectArray,
                gtCarDropArray: callIn.gtCarDropArray,
                gtDryBulkCollect: callIn.gtDryBulkCollect,
                gtDryBulkDrop: callIn.gtDryBulkDrop,
                gtLiquidCollect: callIn.gtLiquidCollect,
                gtLiquidDrop: callIn.gtLiquidDrop,
                gtPalletCollect: callIn.gtPalletCollect,
                gtPalletDrop: callIn.gtPalletDrop,
                gtContainerCollect: callIn.gtContainerCollect,
                gtContainerDrop: callIn.gtContainerDrop,
                gtPalletForceCollect: callIn.gtPalletForceCollect,
                gtPalletForceDrop: callIn.gtPalletForceDrop,
                gtManufacturedProductCollect: callIn.gtManufacturedProductCollect,
                gtManufacturedProductDrop: callIn.gtManufacturedProductDrop,
                callInFromStop: callIn.callInFromStop,
                overwrite: callIn.overwrite,
                discrepancy: callIn.discrepancy,
                reference: callIn.reference,
                notes: callIn.notes,
                discrepancies: callIn.discrepancies
            })
            .toPromise();
    };

    /**
     * Post request to undo previous call-in for leg - reset status and arrival/departure times
     * @param legId
     * @param stopId
     * @returns {Promise<any>}
     */
    public updateUncallInLeg = (legId: number, stopId: number): Promise<any> => {
        return this.http
            .post<any>(`${SERVER_API_URL}api/legs/remove-call-in`, {
                legId: legId,
                stopId: stopId
            })
            .toPromise();
    };

    // To be reviewed and integrated in updateCommunicateLeg
    public communicateLeg = (ids: number[]): Observable<HttpResponse<any[]>> => {
        return this.http.post<any>(`${SERVER_API_URL}api/legs/communicate`, {legIds: ids}, {observe: 'response'});
    };

    public uncommunicateLeg = (ids: number[]): Observable<HttpResponse<any[]>> => {
        return this.http.post<any>(`${SERVER_API_URL}api/legs/uncommunicate`, {legIds: ids}, {observe: 'response'});
    };

    public updateLegTimes = (legTimes: any[]): Promise<any[]> => {
        return this.http.put<any[]>(`${SERVER_API_URL}api/legs/update-times`, legTimes).toPromise();
    };

    public mergeDuplicateStops = (runId: number): Promise<any[]> => {
        return this.http.put<any[]>(`${SERVER_API_URL}api/legs/merge-duplicate-stops/${runId}`, {}).toPromise();
    };

    public updateLegTrailers = (legTrailers: any): Promise<any[]> => {
        console.log('Update leg trailer api : ', legTrailers);
        return this.http.put<any>(`${SERVER_API_URL}api/legs/update-trailers`, legTrailers).toPromise();
    };

    public updateLegTimesAndNightOut = (legTimes: any[]): Promise<any[]> => {
        return this.http.put<any[]>(`${SERVER_API_URL}api/legs/update-times-and-night-out`, legTimes).toPromise();
    };

    public getOutstandingCallIns = (req?: any): Promise<any> => {
        const options = createRequestOption(req);
        return this.http
            .get<ILeg[]>(`${SERVER_API_URL}api/legs/outstanding-call-ins`, {
                params: options
            })
            .toPromise();
    };

    public getInProgressLegs = (): Promise<any[]> => {
        return this.http.get<ILeg[]>(`${SERVER_API_URL}api/legs/in-progress`).toPromise();
    };

    public getProgressLegVehicleToRunMap = (): Promise<any[]> => {
        return this.http.get<any[]>(`${SERVER_API_URL}api/legs/vehicle-to-run-map`).toPromise();
    };

    public getLegsForVehicle = (vehicleId: number): Promise<any[]> => {
        return this.http.get<ILeg[]>(`${SERVER_API_URL}api/legs/vehicle/${vehicleId}`).toPromise();
    };

    public updatePlanningInfo = (legId: number, req: any): Promise<HttpResponse<any[]>> => {
        console.log(req);
        return this.http.post<any>(`${SERVER_API_URL}api/legs/${legId}/planning-info`, req, {observe: 'response'}).toPromise();
    };

    public updateShiftType = (legId: number, req: any): Promise<HttpResponse<any[]>> => {
        console.log(req);
        return this.http
            .post<any>(`${SERVER_API_URL}api/legs/${legId}/shift-type`, req, {
                observe: 'response'
            })
            .toPromise();
    };

    public getLegFromId = (legId: number): Promise<ILeg> => {
        return this.http.get<any>(`${SERVER_API_URL}api/legs/${legId}`).toPromise();
    };

    public generatePil = async (legIds: any[], preview = false): Promise<any> => {
        const type = preview === true ? 'blob' : 'arraybuffer';
        const legIdsString = legIds.join(',');
        return await this.http
            .get<any>(`${SERVER_API_URL}api/legs/${legIdsString}/generate-pil`, {
                responseType: type as 'json'
            })
            .toPromise();
    };

    public getLegCardsToPlan = (filters): Promise<HttpResponse<ILeg[]>> => {
        const filterObj = createRequestOption2(filters);
        return this.http.get<ILeg[]>(`${SERVER_API_URL}api/planner-board-legs`, {params: filterObj, observe: 'response'}).toPromise();
    };
}
